import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Date from "../Common/Date";
import Footer from "../Common/Footer";
import Overviews from "../Common/Overviews";
import Commonheader from "../Common/Commonheader";
import { useAuthHandling } from "../Common/useAuthHandling";
import Config from "../Config";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Toastmodal from "../Common/Toastmodal";
import Select from "react-select";
import Loading from "../Loading";
import { UserContext } from "../Contexts/UserContext";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Dashboard() {
  let navigate = useNavigate();
  const { updateHotel_select, hotel_selectiddash} =useContext(UserContext);

  const storedData = localStorage.getItem("allhotel");
  let store = [];
  
  try {
    store = storedData ? JSON.parse(storedData) : [];
  } catch (error) {
    console.error("Invalid JSON in localStorage for allhotel:", error);
  }
  
  const [hotel_select, setHotel_select] = useState(store ? store : hotel_selectiddash || []);

  // const [hotel_select, setHotel_select] = useState(() => {
   
  //   return storedData ? JSON.parse(storedData) : hotel_selectiddash;
  // });


  const [hotel_selectid, setHotel_selectid] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [level, setLevel] = useState([]);
  const [averageroomrate, setAverageroomrate] = useState([]);
  const [cancelleddata, setCancelleddata] = useState([]);
  const [originalreservation, setOriginalreservation] = useState([]);
  const [totalreservation, setTotalreservation] = useState([]);
  const [upselldata, setUpselldata] = useState([]);
  const [averageroomrate_last7, setAverageroomrate_last7] = useState([]);
  const [cancelleddata_last7, setCancelleddata_last7] = useState([]);
  const [originalreservation_last7, setOriginalreservation_last7] = useState([]);
  const [totalreservation_last7, setTotalreservation_last7] = useState([]);
  const [upselldata_last7, setUpselldata_last7] = useState([]);
  const [averageroomrate_today, setAverageroomrate_today] = useState([]);
  const [cancelleddata_today, setCancelleddata_today] = useState([]);
  const [originalreservation_today, setOriginalreservation_today] = useState([]);
  const [totalreservation_today, setTotalreservation_today] = useState([]);
  const [upselldata_today, setUpselldata_today] = useState([]);
  const [list, setList] = useState([]);
  const [listall, setListall] = useState([]);

  const [dateRange, setDateRange] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [isDefaultRange, setIsDefaultRange] = useState(true);
  const [currency, setCurrency] = useState([]);
  const [selectedcurrency,setSelectedcurrency]=useState({});
  const [country, setCountry] = useState([]);
  const [currencycode, setCurrencycode] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cards,setCards]=useState([]);

  const [ip, setIp] = useState("");
  const [countrycode, setCountrycode] = useState("");


  const handleCalendarChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setDateRange(selectedDates);
      setIsDefaultRange(false);
      Search(selectedDates); 
    }
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const Search = async (selectedDates) => {

    const fromDate = moment(selectedDates[0]).format("DD-MM-YYYY");
    const untilDate = moment(selectedDates[1]).format("DD-MM-YYYY");

      const res = await fetch(
        `${Config.apiurl}reservation-list/search`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotel_selectid,
            from:fromDate,
            until:untilDate
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();

        if (data.status_code === 200) {
          setLoader(true);
          setList(data.data);
          setListall(data.data);

        } else if (data.reauth === true) {
          handleUnauthorized();
          Search();
        } else {
          console.log("error");
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Search();
      } else {
        console.log("error");
      }
      // if (res.status === 200) {
      //   const data = await res.json();
      //   if (data.status_code !== 200 || !data.data) {
      //     console.log("error");
      //   } else {
      //     setList(data.data);
      //     setListall(data.data);
      //     setLoader(true);

      //   }
      // } else if (res.status === 401) {
      //   handleUnauthorized();
      //   Search();
      // } else {
      //   console.log("error");
      // }
  };


  const getData = async (e) => {
    if(hotel_selectid.length>0){
      const res = await fetch(`${Config.apiurl}reservation-list`, {
        method: "POST",
        body: JSON.stringify({
          hotels: hotel_selectid,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();

        if (data.status_code === 200) {
          setList(data.data);
          setListall(data.data);
          // setLoader(true);

        } else if (data.reauth === true) {
          handleUnauthorized();
          getData();
        } else {
          console.log("error");
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
  }
  };


  useEffect(() => {
    if(store.length>0 ||hotel_selectiddash.length>0){
    if (hotel_select.length === 0 && !sessionStorage.getItem("redirected")) {
      sessionStorage.setItem("redirected", "true");
      window.location.href = "/dashboard";
    }
  }
  }, );

  useEffect(() => {
    if (hotel_select) {
      let idd = [];
      for (let i = 0; i < hotel_select.length; i++) {
        if (
          hotel_select[i]?.isGroupChild &&
          hotel_select[i]?.isGroupChild === true
        ) {
          idd.push(hotel_select[i].value);
        } else if (
          !hotel_select[i]?.isGroup &&
          !hotel_select[i]?.isGroupChild
        ) {
          idd.push(hotel_select[i].value);
        }
      }
      setHotel_selectid(idd);
    }
  }, [hotel_select]);


  useEffect(() => {
    getData();
  }, [hotel_selectid]);

  useEffect(() => {
    const startOfMonth = moment().startOf('month').toDate();
    
    const today = moment().toDate();
  
    setDateRange([startOfMonth, today]);
  }, []);


  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    
    dbValue = dbValue.toString();
    let numericValue = parseFloat(dbValue.replace(/,/g, ""));
    
    let result = numericValue.toFixed(2);
    
    return result;
  }

  const currentMonthDays = () => {
    return moment().date(); 
  };

  const getcurrency = async (e) => {

    const res = await fetch(`${Config.apiurl}currencies-select`, {
      method: "GET",
     
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCurrency(data);
    }
  }

  const getcountry = async (e) => {

    const res = await fetch(`${Config.apiurl}countries`, {
      method: "GET",
     
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCountry(data);
    }
  }

  useEffect(()=>{
    getcurrency();
    getcountry();
  },[])


  useEffect(() => {
    if (country && countrycode) {
      for (let i = 0; i < country.length; i++) {
        if (country[i].alpha_code2 === countrycode) {
          if (Object.keys(selectedcurrency).length === 0) {
           setSelectedcurrency(country[i].currencydata);
           setCurrencycode(country[i].currencydata.code);
           sessionStorage.setItem("dash_select_currency", JSON.stringify(country[i].currencydata)); 
           sessionStorage.setItem("dash_select_currency_code", country[i].currencydata.code); 
       
          }

        }
      }
    }
  }, [country.length, countrycode, selectedcurrency]);

  function handleselect(data){
    setSelectedcurrency(data);
    setCurrencycode(data.code);

    sessionStorage.setItem("dash_select_currency", JSON.stringify(data)); 
    sessionStorage.setItem("dash_select_currency_code", data.code); 
  }

  function processDBValuenew(dbValue) {
    // Ensure dbValue is not null or undefined
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));

    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;

    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }
console.log(listall);

  useEffect(()=>{

   if(listall.month_to_date?.average_room_rate){
      let av=listall.month_to_date?.average_room_rate;
      let cance=listall.month_to_date?.cancelled_reservation_rate
      let or=listall.month_to_date?.original_reservation_rate
      let up=listall.month_to_date?.upsell_reservation_rate
      let to=listall.month_to_date?.reservation_rate
      let average=0;
      let cancel=0;
      let orginal=0;
      let upsell=0;
      let total=0;
        const fetchPromises = av.map(async (room) => {
          if (currencycode) {
              const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
              
              try {
                  const response = await fetch(url);
                  const data = await response.json();

                  if (Object.hasOwn(data.rates, currencycode)) {
                      const exchangeRate = data.rates[currencycode];
                    
                      return processDBValuenew(room.rate) * exchangeRate;
                  } else {
                      throw new Error("Exchange rate not found");
                  }
              } catch (error) {
                  console.error("Error:", error);
                  return 0;  
              }
          }
          return 0;
      });

      Promise.all(fetchPromises).then((results) => {
          average = results.reduce((acc, val) => acc + val, 0);
          setAverageroomrate(average);
      });

      const fetchPromises1 = cance.map(async (room) => {
        if (currencycode) {
            const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
            
            try {
                const response = await fetch(url);
                const data = await response.json();

                if (Object.hasOwn(data.rates, currencycode)) {
                    const exchangeRate = data.rates[currencycode];
                    return processDBValuenew(room.rate) * exchangeRate;
                } else {
                    throw new Error("Exchange rate not found");
                }
            } catch (error) {
                console.error("Error:", error);
                return 0;  
            }
        }
        return 0;
    });

    Promise.all(fetchPromises1).then((results) => {
        cancel = results.reduce((acc, val) => acc + val, 0);
        setCancelleddata(cancel);
    });



    const fetchPromises2 = or.map(async (room) => {
      if (currencycode) {
          const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
          
          try {
              const response = await fetch(url);
              const data = await response.json();

              if (Object.hasOwn(data.rates, currencycode)) {
                  const exchangeRate = data.rates[currencycode];
                  return processDBValuenew(room.rate) * exchangeRate;
              } else {
                  throw new Error("Exchange rate not found");
              }
          } catch (error) {
              console.error("Error:", error);
              return 0; 
          }
      }
      return 0;
  });

  Promise.all(fetchPromises2).then((results) => {
      orginal = results.reduce((acc, val) => acc + val, 0);
      setOriginalreservation(orginal);
  });

  const fetchPromises3 = up.map(async (room) => {
    if (currencycode) {
        const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
        
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (Object.hasOwn(data.rates, currencycode)) {
                const exchangeRate = data.rates[currencycode];
                return processDBValuenew(room.rate) * exchangeRate;
            } else {
                throw new Error("Exchange rate not found");
            }
        } catch (error) {
            console.error("Error:", error);
            return 0;  
        }
    }
    return 0;
});

Promise.all(fetchPromises3).then((results) => {
    upsell = results.reduce((acc, val) => acc + val, 0);
    setUpselldata(upsell);
});

const fetchPromises4 = to.map(async (room) => {
  if (currencycode) {
      const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
      
      try {
          const response = await fetch(url);
          const data = await response.json();

          if (Object.hasOwn(data.rates, currencycode)) {
              const exchangeRate = data.rates[currencycode];
              return processDBValuenew(room.rate) * exchangeRate;
          } else {
              throw new Error("Exchange rate not found");
          }
      } catch (error) {
          console.error("Error:", error);
          return 0;  
      }
  }
  return 0;
});

Promise.all(fetchPromises4).then((results) => {
  total = results.reduce((acc, val) => acc + val, 0);
  setTotalreservation(total);
});
   }

  if(listall.today?.average_room_rate){
    let av_today=listall.today?.average_room_rate;
    let cance_today=listall.today?.cancelled_reservation_rate
    let or_today=listall.today?.original_reservation_rate
    let up_today=listall.today?.upsell_reservation_rate
    let to_today=listall.today?.reservation_rate
    let average_today=0;
    let cancel_today=0;
    let orginal_today=0;
    let upsell_today=0;
    let total_today=0;
      const fetchPromises_today = av_today.map(async (room) => {
        if (currencycode) {
            const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
            
            try {
                const response = await fetch(url);
                const data = await response.json();

                if (Object.hasOwn(data.rates, currencycode)) {
                    const exchangeRate = data.rates[currencycode];
                  
                    return processDBValuenew(room.rate) * exchangeRate;
                } else {
                    throw new Error("Exchange rate not found");
                }
            } catch (error) {
                console.error("Error:", error);
                return 0;  
            }
        }
        return 0;
    });

    Promise.all(fetchPromises_today).then((results) => {
        average_today = results.reduce((acc, val) => acc + val, 0);
        setAverageroomrate_today(average_today);
    });

    const fetchPromises_today1 = cance_today.map(async (room) => {
      if (currencycode) {
          const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
          
          try {
              const response = await fetch(url);
              const data = await response.json();

              if (Object.hasOwn(data.rates, currencycode)) {
                  const exchangeRate = data.rates[currencycode];
                  return processDBValuenew(room.rate) * exchangeRate;
              } else {
                  throw new Error("Exchange rate not found");
              }
          } catch (error) {
              console.error("Error:", error);
              return 0;  
          }
      }
      return 0;
  });

  Promise.all(fetchPromises_today1).then((results) => {
      cancel_today = results.reduce((acc, val) => acc + val, 0);
      setCancelleddata_today(cancel_today);
  });



  const fetchPromises_today2 = or_today.map(async (room) => {
    if (currencycode) {
        const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
        
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (Object.hasOwn(data.rates, currencycode)) {
                const exchangeRate = data.rates[currencycode];
                return processDBValuenew(room.rate) * exchangeRate;
            } else {
                throw new Error("Exchange rate not found");
            }
        } catch (error) {
            console.error("Error:", error);
            return 0; 
        }
    }
    return 0;
  });

  Promise.all(fetchPromises_today2).then((results) => {
    orginal_today = results.reduce((acc, val) => acc + val, 0);
    setOriginalreservation_today(orginal_today);
  });

  const fetchPromises_today3 = up_today.map(async (room) => {
  if (currencycode) {
      const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
      
      try {
          const response = await fetch(url);
          const data = await response.json();

          if (Object.hasOwn(data.rates, currencycode)) {
              const exchangeRate = data.rates[currencycode];
              return processDBValuenew(room.rate) * exchangeRate;
          } else {
              throw new Error("Exchange rate not found");
          }
      } catch (error) {
          console.error("Error:", error);
          return 0;  
      }
  }
  return 0;
  });

  Promise.all(fetchPromises_today3).then((results) => {
  upsell_today = results.reduce((acc, val) => acc + val, 0);
  setUpselldata_today(upsell_today);
  });

  const fetchPromises_today4 = to_today.map(async (room) => {
  if (currencycode) {
    const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
    
    try {
        const response = await fetch(url);
        const data = await response.json();

        if (Object.hasOwn(data.rates, currencycode)) {
            const exchangeRate = data.rates[currencycode];
            return processDBValuenew(room.rate) * exchangeRate;
        } else {
            throw new Error("Exchange rate not found");
        }
    } catch (error) {
        console.error("Error:", error);
        return 0;  
    }
  }
  return 0;
  });

  Promise.all(fetchPromises_today4).then((results) => {
  total_today = results.reduce((acc, val) => acc + val, 0);
  setTotalreservation_today(total_today);
  });
  }
   
  if(listall.last_7_days?.average_room_rate){
    let av_7_days=listall.last_7_days?.average_room_rate;
    let cance_7_days=listall.last_7_days?.cancelled_reservation_rate
    let or_7_days=listall.last_7_days?.original_reservation_rate
    let up_7_days=listall.last_7_days?.upsell_reservation_rate
    let to_7_days=listall.last_7_days?.reservation_rate
    let average_7_days=0;
    let cancel_7_days=0;
    let orginal_7_days=0;
    let upsell_7_days=0;
    let total_7_days=0;
      const fetchPromises_7_days = av_7_days.map(async (room) => {
        if (currencycode) {
            const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
            
            try {
                const response = await fetch(url);
                const data = await response.json();

                if (Object.hasOwn(data.rates, currencycode)) {
                    const exchangeRate = data.rates[currencycode];
                  
                    return processDBValuenew(room.rate) * exchangeRate;
                } else {
                    throw new Error("Exchange rate not found");
                }
            } catch (error) {
                console.error("Error:", error);
                return 0;  
            }
        }
        return 0;
    });

    Promise.all(fetchPromises_7_days).then((results) => {
        average_7_days = results.reduce((acc, val) => acc + val, 0);
        setAverageroomrate_last7(average_7_days);
    });

    const fetchPromises_7_days1 = cance_7_days.map(async (room) => {
      if (currencycode) {
          const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
          
          try {
              const response = await fetch(url);
              const data = await response.json();

              if (Object.hasOwn(data.rates, currencycode)) {
                  const exchangeRate = data.rates[currencycode];
                  return processDBValuenew(room.rate) * exchangeRate;
              } else {
                  throw new Error("Exchange rate not found");
              }
          } catch (error) {
              console.error("Error:", error);
              return 0;  
          }
      }
      return 0;
  });

  Promise.all(fetchPromises_7_days1).then((results) => {
      cancel_7_days = results.reduce((acc, val) => acc + val, 0);
      setCancelleddata_last7(cancel_7_days);
  });



  const fetchPromises_7_days2 = or_7_days.map(async (room) => {
    if (currencycode) {
        const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
        
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (Object.hasOwn(data.rates, currencycode)) {
                const exchangeRate = data.rates[currencycode];
                return processDBValuenew(room.rate) * exchangeRate;
            } else {
                throw new Error("Exchange rate not found");
            }
        } catch (error) {
            console.error("Error:", error);
            return 0; 
        }
    }
    return 0;
});

Promise.all(fetchPromises_7_days2).then((results) => {
    orginal_7_days = results.reduce((acc, val) => acc + val, 0);
    setOriginalreservation_last7(orginal_7_days);
});

const fetchPromises_7_days3 = up_7_days.map(async (room) => {
  if (currencycode) {
      const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
      
      try {
          const response = await fetch(url);
          const data = await response.json();

          if (Object.hasOwn(data.rates, currencycode)) {
              const exchangeRate = data.rates[currencycode];
              return processDBValuenew(room.rate) * exchangeRate;
          } else {
              throw new Error("Exchange rate not found");
          }
      } catch (error) {
          console.error("Error:", error);
          return 0;  
      }
  }
  return 0;
});

Promise.all(fetchPromises_7_days3).then((results) => {
  upsell_7_days = results.reduce((acc, val) => acc + val, 0);
  setUpselldata_last7(upsell_7_days);
});

const fetchPromises_7_days4 = to_7_days.map(async (room) => {
if (currencycode) {
    const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
    
    try {
        const response = await fetch(url);
        const data = await response.json();

        if (Object.hasOwn(data.rates, currencycode)) {
            const exchangeRate = data.rates[currencycode];
            return processDBValuenew(room.rate) * exchangeRate;
        } else {
            throw new Error("Exchange rate not found");
        }
    } catch (error) {
        console.error("Error:", error);
        return 0;   
    }
}
return 0;
});

Promise.all(fetchPromises_7_days4).then((results) => {
  total_7_days = results.reduce((acc, val) => acc + val, 0);
  setTotalreservation_last7(total_7_days);
  setLoader(true);

  });
  }

  },[listall,currencycode])


  useEffect(()=>{
    setCards([
      {
        id: "1",
        borderColor: "#A48BCF",
        title: "INITIAL RESERVATIONS/ + REVENUE",
        reservationCount: list.month_to_date?.total_reservation_count,
        reservationCount_last7days: list.last_7_days?.total_reservation_count,
        reservationCount_today: list.today?.total_reservation_count,
        reservationLabel: "Reservations",
        revenue: totalreservation ? processDBValue(totalreservation) : "0",
        revenue_last7days: totalreservation_last7 ? processDBValue(totalreservation_last7) : "0",
        revenue_today: totalreservation_today ? processDBValue(totalreservation_today) : "0",
        iconClass: "bx bx-calendar-x text-danger",
      },
      {
        id: "2",
        borderColor: "#E06F95",
        title: "CANCELLATIONS / - REVENUE",
        reservationCount: list.month_to_date?.cancelled_reservation_count,
        reservationCount_last7days: list.last_7_days?.cancelled_reservation_count,
        reservationCount_today: list.today?.cancelled_reservation_count,
        reservationLabel: "Cancellations",
        revenue: cancelleddata ? processDBValue(cancelleddata) : "0",
        revenue_last7days: cancelleddata_last7? processDBValue(cancelleddata_last7) : "0",
        revenue_today: cancelleddata_today ? processDBValue(cancelleddata_today) : "0",
        iconClass: "bx bx-calendar-x text-danger",
      },
      {
        id: "3",
        borderColor: "#E5A43D",
        title: "ROOM NIGHTS /ARR",
        reservationCount: list.month_to_date?.original_room_nights,
        reservationCount_last7days: list.last_7_days?.original_room_nights,
        reservationCount_today: list.today?.original_room_nights,
        reservationLabel: "Room Nights",
        revenue: averageroomrate ? processDBValue(averageroomrate) : "0",
        revenue_last7days: averageroomrate_last7 ? processDBValue(averageroomrate_last7) : "0",
        revenue_today: averageroomrate_today ? processDBValue(averageroomrate_today) : "0",
        iconClass: "bx bxs-edit text-info",
      },
      {
        id: "4",
        borderColor: "#EBCF54",
        title: "UPSELLS /+ REVENUE",
        reservationCount: list.month_to_date?.upsell_reservation_count,
        reservationCount_last7days: list.last_7_days?.upsell_reservation_count,
        reservationCount_today: list.today?.upsell_reservation_count,
        reservationLabel: "Upsells",
        revenue: upselldata ? processDBValue(upselldata) : "0",
        revenue_last7days: upselldata_last7 ? processDBValue(upselldata_last7) : "0",
        revenue_today: upselldata_today ? processDBValue(upselldata_today) : "0",
        iconClass: "bx bxs-edit text-success",
      },
      {
        id: "5",
        borderColor: "#81C784",
        title: "ORIGINAL RESERVATIONS /+ REVENUE",
        reservationCount: list.month_to_date?.original_reservation_count,
        reservationCount_last7days: list.last_7_days?.original_reservation_count,
        reservationCount_today: list.today?.original_reservation_count,
        reservationLabel: "Reservations",
        revenue: originalreservation ? processDBValue(originalreservation) : "0",
        revenue_last7days: originalreservation_last7 ? processDBValue(originalreservation_last7) : "0",
        revenue_today: originalreservation_today ? processDBValue(originalreservation_today) : "0",
        iconClass: "bx bxs-edit text-success",
      },
      {
        id: "6",
        borderColor: "#F0E687",
        title: "LOYALTY",
        reservationCount: list.month_to_date?.total_level_count,
        reservationCount_last7days: list.last_7_days?.total_level_count,
        reservationCount_today: list.today?.total_level_count,

        loyaltyDetails: list.month_to_date?.level_array,
        loyaltyDetails_last7days: list.last_7_days?.level_array,
        loyaltyDetails_today: list.today?.level_array,
        iconClass: "bx bxs-edit text-danger",
      }
    ])
  },[listall,totalreservation,totalreservation_last7,totalreservation_today,cancelleddata,cancelleddata_last7,cancelleddata_today,averageroomrate,averageroomrate_last7,averageroomrate_today,upselldata,upselldata_last7,upselldata_today,originalreservation,originalreservation_last7,originalreservation_today])


  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    console.log(destination,source);
    const updatedItems = Array.from(cards);
    // Remove the item from its source index
    const [removed] = updatedItems.splice(source.index, 1);
    // Insert the removed item at the destination index
    updatedItems.splice(destination.index, 0, removed);
    
    // Update state with the new order
    setCards(updatedItems);
    // const draggedElement = document.querySelector(`#card-${source.index}`);
    // const destinationElement = document.querySelector(`#card-${destination.index}`);

    // if (draggedElement && destinationElement) {
    //   const parent = draggedElement.parentElement;
    //   parent.insertBefore(draggedElement, destinationElement);
    // }
  };


console.log(dateRange);


  return (
    <>
      <div id="layout-wrapper">
        <Commonheader />

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row project-wrapper">
                  
                  <div className="row dashboard-top-tab-style dashboard-top-tab-style_new">
                  <div className=" col-xl-6 col-lg-5 dashboard-main-style">
                    <div className="col-xxl-8">
                      <Overviews setIp={setIp} setCountrycode={setCountrycode} ip={ip} setSelectedcurrency={setSelectedcurrency} setCurrencycode={setCurrencycode}
                     />
                    </div>
                    
                  </div>

                  <div className="col-xl-3 col-lg-4 col-sm-6 dashboard_select_full">
                  <label className="input-group-tex" for="inputGroupSelect01">Currency</label>
                  <Select
                      value={selectedcurrency}
                      onChange={(data)=>handleselect(data)}
                      options={currency}
                      name="choices-single-default"
                      id="idStatus"
                      className="form-control dashboard_select"
                  ></Select>
                  </div>
                  
                <div className="  col-xl-3 col-lg-3 col-sm-6 form-calender-add">
                    <form action="#">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                          placeholder="Choose date"
                          id="flatpickr-from"
                          options={{
                            mode: "range",
                            dateFormat: "d M, Y",
                          }}
                          value={dateRange}
                          onChange={handleCalendarChange}
                        />
                        
                        <span
                          className="dashboard_calendar_new fon mdi mdi-calendar-month-outline"
                          onClick={() =>handleCalendarIconClick("from")}></span>
                      </div>
                    </form>
                  </div>
                </div>
                {loader===false?
                <Loading/>
                :
                <div className="row dashboard-top-tab-style">
                  <div className="col-xl-9 dashboard-main-style">
                    {/* <div className="row"> */}
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="dashboard-cards" direction="horizontal">
                        {(provided) => (
                          <div
                            className="row"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {cards.map((card, index) => (
                              <Draggable
                                key={card.id}
                                draggableId={`card-${card.id}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="col-xl-4 col-md-4"
                                    id={`card-${card.id}`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="card card-animate">
                                      <div
                                        className="card-body"
                                        style={{ borderTop: `4px solid ${card.borderColor}` }}
                                      >
                                        <div className="align-items-end justify-content-between">
                                          <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                              {card.title}
                                            </p>
                                            <div className="overview-today-sec d-flex">
                                              {/* {isDefaultRange && ( */}
                                                <p className="dashboard-date-style">
                                                  Today
                                                </p>
                                              {/* )} */}
                                               <div className="dashboard_price_align">
                                        {card.id !== "6"?
                                            <>

                                        <div className="align-items-end justify-content-between mt-2">
                                          <h4 className="fs-16 fw-semibold ff-secondary mb-2">
                                            {card.reservationCount_today}
                                           
                                            / 
                                            {card.id !=="2"?
                                            <span className="rev-plus">+</span>
                                            :
                                            <span className="rev-minus"> -</span>
                                            }

                                            <span className="dashboard_currency">
                                              {" "}
                                              {currencycode}
                                            </span>{" "}
                                            {card.revenue_today ? (
                                              <span>{card.revenue_today}</span>
                                            ) : (
                                              "0"
                                            )}
                                          </h4>
                                          
                                        </div>
                                        </>
                                            :
                                            <div className="d-flex mt-2">
                                            <h4 className="fs-16 fw-semibold ff-secondary mb-2">
                                              {card.reservationCount_today}
  
                                            </h4>
                                            {card.id === "6" && card.loyaltyDetails_today && (
                                            <div className="loyalty-details">
                                              {card.loyaltyDetails_today.map((data, i) => (
                                                <span key={i} className="loyalty-base">
                                                  {data.level} - {data.count}
                                                </span>
                                              ))}
                                            </div>
                                          )}
                                          </div>
                                            }
                                              </div>
                                             
                                            </div>
                                            <div className="overview-today-sec d-flex">
                                              {/* {isDefaultRange && ( */}
                                                <p className="dashboard-date-style">
                                                  Last 7 days
                                                </p>
                                              {/* )} */}
                                               <div className="dashboard_price_align">
                                        {card.id !== "6"?
                                            <>

                                        <div className="align-items-end justify-content-between mt-2">
                                          <h4 className="fs-16 fw-semibold ff-secondary mb-2">
                                            {card.reservationCount_last7days}
                                           
                                            /  {card.id !=="2"?
                                            <span className="rev-plus">+</span>
                                            :
                                            <span className="rev-minus"> -</span>
                                            }
                                            <span className="dashboard_currency">
                                              {" "}
                                              {currencycode}
                                            </span>{" "}
                                            {card.revenue_last7days ? (
                                              <span>{card.revenue_last7days}</span>
                                            ) : (
                                              "0"
                                            )}
                                          </h4>
                                          
                                        </div>
                                        </>
                                            :
                                            <div className="d-flex mt-2">
                                            <h4 className="fs-16 fw-semibold ff-secondary mb-2">
                                              {card.reservationCount_last7days}
  
                                            </h4>
                                            {card.id === "6" && card.loyaltyDetails_last7days && (
                                            <div className="loyalty-details">
                                              {card.loyaltyDetails_last7days.map((data, i) => (
                                                <span key={i} className="loyalty-base">
                                                  {data.level} - {data.count}
                                                </span>
                                              ))}
                                            </div>
                                          )}
                                          </div>
                                            }
                                              </div>
                                             
                                            </div>
                                              <div className="overview-today-sec d-flex">
                                              {/* {isDefaultRange && ( */}
                                              {isDefaultRange === false?
                                               <p className="dashboard-date-style">
                                                Search
                                               {/* {moment(dateRange[0]).format("DD MMM,YYYY")} - {moment(dateRange[1]).format("DD MMM,YYYY")} */}
                                             </p>
                                             :
                                                <p className="dashboard-date-style">
                                                  {/* Last {currentMonthDays()} days */}
                                                  This Month
                                                </p>
                                               }
                                               <div className="dashboard_price_align">
                                        {card.id !== "6"?
                                            <>

                                        <div className="align-items-end justify-content-between mt-2">
                                          <h4 className="fs-16 fw-semibold ff-secondary mb-2">
                                            {card.reservationCount}
                                            {/* <span className="value-specify-span">
                                              {card.reservationLabel}
                                            </span> */}
                                            /  {card.id !=="2"?
                                            <span className="rev-plus">+</span>
                                            :
                                            <span className="rev-minus"> -</span>
                                            }
                                            <span className="dashboard_currency">
                                              {" "}
                                              {currencycode}
                                            </span>{" "}
                                            {card.revenue ? (
                                              <span>{card.revenue}</span>
                                            ) : (
                                              "0"
                                            )}
                                          </h4>
                                          
                                        </div>
                                        </>
                                            :
                                            <div className="d-flex mt-2">
                                            <h4 className="fs-16 fw-semibold ff-secondary mb-2">
                                              {card.reservationCount}
  
                                            </h4>
                                            {card.id === "6" && card.loyaltyDetails && (
                                            <div className="loyalty-details">
                                              {card.loyaltyDetails.map((data, i) => (
                                                <span key={i} className="loyalty-base">
                                                  {data.level} - {data.count}
                                                </span>
                                              ))}
                                            </div>
                                          )}
                                          </div>
                                            }
                                              </div>
                                              {/* <div className="flex-shrink-0 icon_body dashboard-date-icon">
                                                <span className="avatar-title bg-soft-danger rounded fs-3">
                                                  <i className={card.iconClass}></i>
                                                </span>
                                              </div> */}
                                            </div>
                                          </div>
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {/* </div> */}
                  </div>
                  <div className="col-xl-3 dashboard-main-style">
                    <div className="row">
                      <div className="col-xl-12 col-md-12">
                        <div className="card">
                          <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              Latest News
                            </h4>
                          </div>
                          <div className="card-body">
                            <h5 className="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p className="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 className="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p className="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 className="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p className="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. In addition, Read
                              more..
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
          {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
