import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import axios from "axios";
import Alerts from "../../Alerts";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { UserContext } from "../../Contexts/UserContext";
import ReactPaginate from "react-paginate";
import Toastmodal from "../../Common/Toastmodal";
import Footer from "../../Common/Footer";
import Loading from "../../Loading";

function Taxedit() {
  const [be_language, setBe_language] = useState([]);
  const [multi, setMulti] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [status, setStatus] = useState([]);
  const [roomtype, setRoomtype] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [formerror, setError] = useState([{}]);
  const [removetaxid, setRemovetaxid] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [List, setList] = useState([]);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const [inputFieldstax, setInputFieldstax] = useState([]);

  const addInputFieldtax = (hotelIndex) => {
    const updatedInputFieldstax = [...inputFieldstax];
    const hotel = updatedInputFieldstax[hotelIndex];
    hotel.hotel_taxes.push({
      amount_type: "percentage",
      increment_type: "",
      roomtax_info: [],
      multi_lang: [],
    });
    setInputFieldstax(updatedInputFieldstax);
  };

  // const removeInputFieldstax = (hotelIndex, taxIndex) => {
  //   const updatedInputFieldstax = [...inputFieldstax];
  //   const hotelTaxes = updatedInputFieldstax[hotelIndex]?.hotel_taxes;
  //   const deletedTax = hotelTaxes.splice(taxIndex, 1)[0];
  //   removetaxid.push(deletedTax.tax_id);
  //   setInputFieldstax(updatedInputFieldstax);

  //   const changedDataCopy = [...changeddata];

  //   changedDataCopy[hotelIndex] = {
  //     ...changedDataCopy[hotelIndex],
  //     hotel_id: inputFieldstax[hotelIndex].hotel_id,
  //     hotel_taxes: updatedInputFieldstax[hotelIndex]?.hotel_taxes,
  //     tax_include_status: inputFieldstax[hotelIndex].tax_include_status,
  //     removeTax: removetaxid,
  //   };

  //   setChangeddata(changedDataCopy);
  // };

  const removeInputFieldstax = (hotelIndex, taxIndex) => {
    const updatedInputFieldstax = [...inputFieldstax];
    const hotelTaxes = updatedInputFieldstax[hotelIndex]?.hotel_taxes;
    const deletedTax = hotelTaxes.splice(taxIndex, 1)[0];

    if (!removetaxid[hotelIndex]) {
      removetaxid[hotelIndex] = [];
    }
    removetaxid[hotelIndex].push(deletedTax.tax_id);
    setInputFieldstax(updatedInputFieldstax);

    const changedDataCopy = [...changeddata];

    changedDataCopy[hotelIndex] = {
      ...changedDataCopy[hotelIndex],
      hotel_id: inputFieldstax[hotelIndex].hotel_id,
      hotel_taxes: updatedInputFieldstax[hotelIndex]?.hotel_taxes,
      tax_include_status: inputFieldstax[hotelIndex].tax_include_status,
      removeTax: removetaxid[hotelIndex],
    };

    setChangeddata(changedDataCopy);
  };

  let handleChangetax = (i, index, e) => {
    let newval = [...inputFieldstax];
    inputFieldstax[i]["hotel_taxes"][index][e.target.name] = e.target.value;
    setInputFieldstax(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: inputFieldstax[i].hotel_id,
      tax_include_status: inputFieldstax[i].tax_include_status,
      hotel_taxes: inputFieldstax[i]["hotel_taxes"].map((hotel, roomIndex) => {
        if (roomIndex === index) {
          return {
            ...hotel,
            [e.target.name]: e.target.value,
          };
        } else {
          return hotel;
        }
      }),
    };
    setChangeddata(changedDataCopy);
  };


  let handleLang = (i, index, ind, e) => {
    let newval = [...inputFieldstax];
    inputFieldstax[i]["hotel_taxes"][index]["multi_lang"][ind][e.target.name] =
      e.target.value;
    setInputFieldstax(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: inputFieldstax[i].hotel_id,
      tax_include_status: inputFieldstax[i].tax_include_status,
      hotel_taxes: inputFieldstax[i]["hotel_taxes"].map((hotel, roomIndex) => {
        if (roomIndex === index) {
          return {
            ...hotel,
            multi_lang: hotel.multi_lang.map((lang, langIndex) => {
              if (langIndex === ind) {
                return {
                  ...lang,
                  [e.target.name]: e.target.value,
                };
              } else {
                return lang;
              }
            }),
          };
        } else {
          return hotel;
        }
      }),
    };
    setChangeddata(changedDataCopy);
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel-tax/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setInputFieldstax(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setInputFieldstax(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const res = await fetch(`${Config.apiurl}hotel-tax/list?page=1`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setInputFieldstax(data.data.data);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
    // const data = await response.json();
    // if (data.status_code === "200") {
    //   setInputFieldstax(data.data.data);
    //   // setInputFieldstax(data.data.data.hotel_taxes);
    //   // setStatus(data.data.data.tax_include_status);

    //   setPaginate(data.data.last_page);
    // } else if (response.reauth === true) {
    //   handleUnauthorized();
    //   fetchPost();
    // } else {
    //   console.log("error");
    // }
  };

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_language(data.data);
    }
  };

  // const getinfo = async (e) => {
  //   const res = await fetch(`${Config.apiurl}hotel-tax/view/${id}`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   });
  //   const data = await res.json();
  //   setInputFieldstax(data.data.tax_info);
  //   setCurrency(data.data.currency);
  //   setStatus(data.data);
  // };


  // const getRoomtype = async (hotelId) => {
  //   const res = await fetch(`${Config.apiurl}active-rooms/${hotelId}`, {
  //     method: "POST",
  //   });
  //   const data1 = await res.json();
  //   const data = data1.data;

  //   if (data1.status_code !== "200" || !data) {
  //     console.log("error");
  //   } else {
  //     setRoomtype(data);
  //   }
  // };
  let Save = async () => {
    let isFormValid = true;

    let arr = [];
    for (let i = 0; i < inputFieldstax.length; i++) {
      for (let j = 0; j < inputFieldstax[i].hotel_taxes.length; j++) {
        const errors = {};
        if (!inputFieldstax[i].hotel_taxes[j].title) {
          isFormValid = false;

          errors.tax_name = "Tax name is required!";
        }
        if (!inputFieldstax[i].hotel_taxes[j].value) {
          isFormValid = false;

          errors.tax_dis = "Rate is required!";
        }
        if (!inputFieldstax[i].hotel_taxes[j].increment_type) {
          isFormValid = false;

          errors.increment_type = "This field is required!";
        }
        if (!inputFieldstax[i].hotel_taxes[j].message) {
          isFormValid = false;

          errors.tax_comment = "Comment is required!";
        }
        if (!inputFieldstax[i].hotel_taxes[j].increment_frequency) {
          isFormValid = false;

          errors.increment_frequency = "Frequency is required!";
        }
        if (
          !inputFieldstax[i].hotel_taxes[j].roomtax_info ||
          inputFieldstax[i].hotel_taxes[j].roomtax_info.length === 0
        ) {
          isFormValid = false;
          errors.roomtax_info = "Room type is required!";
        }
        arr.push(errors);
      }
      setError(arr);

      if (isFormValid === true) {
        let hotels = [];

        for (let i = 0; i < changeddata.length; i++) {
          let hotelObj = {};
          let taxInfo = [];
          let newTaxInfo = [];
          let langCodesSet = new Set();

          hotelObj.hotel_id = changeddata[i].hotel_id;
          hotelObj.tax_include_status = changeddata[i].tax_include_status;
          hotelObj.removed_taxes = changeddata[i].removeTax;

          for (let j = 0; j < changeddata[i].hotel_taxes.length; j++) {
            let taxObj = {};
            let multiLang = [];

            for (
              let k = 0;
              k < changeddata[i].hotel_taxes[j].multi_lang.length;
              k++
            ) {
              langCodesSet.add(
                changeddata[i].hotel_taxes[j].multi_lang[k].code
              );
            }

            if (changeddata[i].hotel_taxes[j].tax_id) {
              taxObj.tax_id = changeddata[i].hotel_taxes[j].tax_id;
              taxObj.title = changeddata[i].hotel_taxes[j].title;
              taxObj.discount = changeddata[i].hotel_taxes[j].value;
              taxObj.amount_type = changeddata[i].hotel_taxes[j].amount_type;
              taxObj.increment_type =
                changeddata[i].hotel_taxes[j].increment_type;
              taxObj.frequency =
                changeddata[i].hotel_taxes[j].increment_frequency;
              taxObj.service_charge_status =
                changeddata[i].hotel_taxes[j].service_charge_status === true
                  ? 1
                  : 0;
              taxObj.service_charge_applicable =
                changeddata[i].hotel_taxes[j].service_charge_applicable === true
                  ? 1
                  : 0;
              taxObj.room_type = changeddata[i].hotel_taxes[j].roomtax_info.map(
                (info) => info.value
              );
              taxObj.message = changeddata[i].hotel_taxes[j].message;

              for (
                let k = 0;
                k < changeddata[i].hotel_taxes[j].multi_lang.length;
                k++
              ) {
                multiLang.push({
                  lang_code: changeddata[i].hotel_taxes[j].multi_lang[k].code,
                  title: changeddata[i].hotel_taxes[j].multi_lang[k].name,
                  message:
                    changeddata[i].hotel_taxes[j].multi_lang[k].description,
                });
              }

              taxObj.multi_lang = multiLang;
              taxInfo.push(taxObj);
            } else {
              taxObj.title = changeddata[i].hotel_taxes[j].title;
              taxObj.discount = changeddata[i].hotel_taxes[j].value;
              taxObj.amount_type = changeddata[i].hotel_taxes[j].amount_type;
              taxObj.increment_type =
                changeddata[i].hotel_taxes[j].increment_type;
              taxObj.frequency =
                changeddata[i].hotel_taxes[j].increment_frequency;
              taxObj.service_charge_status =
                changeddata[i].hotel_taxes[j].service_charge_status === true
                  ? 1
                  : 0;
              taxObj.service_charge_applicable =
                changeddata[i].hotel_taxes[j].service_charge_applicable === true
                  ? 1
                  : 0;
              taxObj.room_type = changeddata[i].hotel_taxes[j].roomtax_info.map(
                (info) => info.value
              );
              taxObj.message = changeddata[i].hotel_taxes[j].message;

              for (
                let k = 0;
                k < changeddata[i].hotel_taxes[j].multi_lang.length;
                k++
              ) {
                multiLang.push({
                  lang_code: changeddata[i].hotel_taxes[j].multi_lang[k].code,
                  title: changeddata[i].hotel_taxes[j].multi_lang[k].name,
                  message:
                    changeddata[i].hotel_taxes[j].multi_lang[k].description,
                });
              }

              taxObj.multi_lang = multiLang;
              newTaxInfo.push(taxObj);
            }
          }
          hotelObj.lang_codes = Array.from(langCodesSet);
          hotelObj.tax_info = taxInfo;
          hotelObj.new_tax_info = newTaxInfo;

          hotels.push(hotelObj);
        }

        const res = await fetch(`${Config.apiurl}hotel-tax/bulk-update`, {
          method: "POST",
          body: JSON.stringify({
            hotels: hotels,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          if (data.status_code === "200" || !data.data) {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("success");
            setToastlarge(true);
            fetchPost();
          } else {
            console.log("error");
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("error");
            setToastlarge(true);
          }
        } else if (res.status === 401) {
          handleUnauthorized();
          Save();
        } else {
          console.log("error");
        }
      }
    }
  };

  // const PostData = async (e) => {
  //   e.preventDefault();

  //   let isFormValid = true;

  //   let arr = [];
  //   for (let i = 0; i < inputFieldstax.length; i++) {
  //     const errors = {};
  //     if (!inputFieldstax[i].title) {
  //       isFormValid = false;

  //       errors.tax_name = "Tax name is required!";
  //     }
  //     if (!inputFieldstax[i].value) {
  //       isFormValid = false;

  //       errors.tax_dis = "Rate is required!";
  //     }
  //     if (!inputFieldstax[i].increment_type) {
  //       isFormValid = false;

  //       errors.tax_appli = "This field is required!";
  //     }
  //     if (!inputFieldstax[i].message) {
  //       isFormValid = false;

  //       errors.tax_comment = "Comment is required!";
  //     }
  //     if (!inputFieldstax[i].rooms || inputFieldstax[i].rooms.length === 0) {
  //       isFormValid = false;
  //       errors.roomtax_info = "Room type is required!";
  //     }
  //     arr.push(errors);
  //   }
  //   setError(arr);

  //   if (isFormValid === true) {
  //     const formData = new FormData();

  //     formData.append("tax_include_status", status.tax_include_status);

  //     for (let i = 0; i < inputFieldstax.length; i++) {
  //       if (inputFieldstax[i].tax_id) {
  //         formData.append(`tax[${i}][id]`, inputFieldstax[i].tax_id);
  //       } else {
  //         formData.append(`tax[${i}][id]`, "");
  //       }
  //       formData.append(`tax[${i}][title]`, inputFieldstax[i].title);
  //       formData.append(`tax[${i}][discount]`, inputFieldstax[i].value);
  //       formData.append(
  //         `tax[${i}][amount_type]`,
  //         inputFieldstax[i].amount_type
  //       );
  //       formData.append(
  //         `tax[${i}][increment_type]`,
  //         inputFieldstax[i].increment_type
  //       );
  //       formData.append(`tax[${i}][message]`, inputFieldstax[i].message);
  //       for (let j = 0; j < inputFieldstax[i].rooms.length; j++) {
  //         formData.append(
  //           `tax[${i}][room_type][${j}]`,
  //           inputFieldstax[i].rooms[j].value
  //         );
  //       }
  //       if (inputFieldstax[i].multi_lang.length > 0) {
  //         for (let k = 0; k < inputFieldstax[i].multi_lang.length; k++) {
  //           formData.append(
  //             `tax[${i}][multi_lang][${k}][lang_code]`,
  //             inputFieldstax[i].multi_lang[k].code
  //           );
  //           formData.append(
  //             `tax[${i}][multi_lang][${k}][title]`,
  //             inputFieldstax[i].multi_lang[k].name
  //           );
  //           formData.append(
  //             `tax[${i}][multi_lang][${k}][message]`,
  //             inputFieldstax[i].multi_lang[k].description
  //           );
  //         }
  //       }
  //     }
  //     if (removetaxid.length > 0) {
  //       for (let l = 0; l < removetaxid.length; l++) {
  //         if (removetaxid[l] !== undefined) {
  //           formData.append(`removed_tax[${l}]`, removetaxid[l]);
  //         }
  //       }
  //     }
  //     axios
  //       .post(`${Config.apiurl}hotel-tax/update/${id}`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-type": "application/json; charset=UTF-8",
  //         },
  //       })
  //       .then((res) => {
  //         if (res.status_code !== "200" || !res) {
  //           setAlert(true);
  //           setAlertmessage(res.message);
  //           setOpen(true);
  //           setAlerttype("error");
  //         } else {
  //           setAlert(true);
  //           setAlertmessage(res.message);
  //           setOpen(true);
  //           setAlerttype("success");
  //         }
  //       });
  //   }
  // };

  useEffect(() => {
    getlanguage();
    // getinfo();
    // getRoomtype();
    fetchPost();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  let handleChangeesroomtypetax = (i, index, data, name) => {
    let newval = [...inputFieldstax];
    inputFieldstax[i]["hotel_taxes"][index][name] = data;
    setInputFieldstax(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: inputFieldstax[i].hotel_id,
      tax_include_status: inputFieldstax[i].tax_include_status,
      hotel_taxes: inputFieldstax[i]["hotel_taxes"].map((tax, taxIndex) => {
        if (taxIndex === index) {
          return {
            ...tax,
            roomtax_info: data,
          };
        } else {
          return tax;
        }
      }),
    };
    setChangeddata(changedDataCopy);
  };

  let handleChangeesLangtax = (i, index, data, name) => {
    let newval = [...inputFieldstax];
    inputFieldstax[i]["hotel_taxes"][index][name] = data;
    setInputFieldstax(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: inputFieldstax[i].hotel_id,
      tax_include_status: inputFieldstax[i].tax_include_status,
      hotel_taxes: inputFieldstax[i]["hotel_taxes"].map((tax, taxIndex) => {
        if (taxIndex === index) {
          return {
            ...tax,
            multi_lang: data,
          };
        } else {
          return tax;
        }
      }),
    };
    setChangeddata(changedDataCopy);
  };

  let handleChangetogghotel = (i, index, e, name) => {
    let newval = [...inputFieldstax];
    inputFieldstax[i]["hotel_taxes"][index][name] = e.target.checked;
    setInputFieldstax(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: inputFieldstax[i].hotel_id,
      tax_include_status: inputFieldstax[i].tax_include_status,
      hotel_taxes: inputFieldstax[i]["hotel_taxes"].map((tax, taxIndex) => {
        if (taxIndex === index) {
          return {
            ...tax,
            name: e.target.checked,
          };
        } else {
          return tax;
        }
      }),
    };
    setChangeddata(changedDataCopy);
  };

  let handleChangetoggTaxapply = (i, e, name) => {
    let newval = [...inputFieldstax];
    inputFieldstax[i][name] = e.target.checked;
    setInputFieldstax(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: inputFieldstax[i].hotel_id,
      tax_include_status: e.target.checked,
      hotel_taxes:inputFieldstax[i].hotel_taxes,
      // hotel_taxes: inputFieldstax[i]["hotel_taxes"].map((tax, taxIndex) => {
      //   if (taxIndex === index) {
      //     return {
      //       ...tax,
      //       name: e.target.checked,
      //     };
      //   } else {
      //     return tax;
      //   }
      // }),
    };
    setChangeddata(changedDataCopy);
  };

  useEffect(() => {
    if (inputFieldstax.length > 0) {
      const initialState = inputFieldstax.map((item, index) => ({
        hotel: index === 0,
        hotel_taxes: Array(item.hotel_taxes.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [inputFieldstax.length]);

  const [symbolsrate] = useState(["e", "E", "+", "-"]);


  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12"></div>

              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="db-page-title">Tax</h4>
                <div className="card-header align-items-center d-flex">
                  <div className="flex-shrink-0">
                    {/* <Link type="button" className="btn db-other-button" to="/tax" style={{ marginRight: "5px" }}>Tax List</Link>
                        <button type="button" className="btn db-cancel-button" data-bs-toggle="offcanvas" to="" > Cancel</button> */}
                    <Link
                      type="button"
                      to="#"
                      className="btn db-save-button "
                      onClick={Save}
                    >
                      Update
                    </Link>
                  </div>
                </div>
              </div>
            </div>
              {loader===false?
              <Loading/>
              :
            <div className="row">
              <div className="card padding_card">
                <div className="addmore_tax_details new_tax_options">
                  {inputFieldstax.map((li, i) => {
                    return (
                      <>
                        <div
                          className={
                            inputFieldstax.length > 1
                              ? "row hotel_single_list"
                              : "row"
                          }
                          key={i}
                        >
                          {inputFieldstax.length > 1 ? (
                            <div className="hotel_service_display hotel_service_display_12">
                              <div className="hotel_service_display_14">
                                <div className="hk_service_all_hotel">
                                  <span className="">
                                    {" "}
                                    {pageindex === 1
                                      ? i + 1
                                      : (pageindex - 1) * 10 + i + 1}
                                    .
                                  </span>
                                  <span style={{ marginLeft: "10px" }}>
                                    {li.hotel_name}
                                  </span>
                                </div>
                              </div>
                              {showFullData[i]?.hotel === true ? (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-up"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              ) : (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-down"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className={`row ${
                              showFullData[i]?.hotel === true ? "" : "d-none"
                            }`}
                            style={{ marginTop: "5px" }}
                          >
                            <div style={{marginTop:"2%"}}>
                                      <div className="mb-4">
                                        <div styl>
                                          <label
                                            for="firstNameinput"
                                            className="form-label be_form_label"
                                            style={{float:"left",marginTop:"5px"}}
                                          >
                                            Tax Include
                                          
                                          </label>
                                          <div
                                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                          dir="ltr" style={{marginLeft:"10px"}}>
                                          <input
                                            type="checkbox"
                                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                                            id="inlineswitch5"
                                            name="service_charge_status"
                                            value={li.tax_include_status}
                                            checked={
                                              li.tax_include_status ===
                                              true
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handleChangetoggTaxapply(
                                                i,
                                                e,
                                                "tax_include_status"
                                              )
                                            }
                                            style={{
                                              width: "50px",
                                              height: "25px",
                                            }}
                                          />
                                        </div>
                                        </div>
                                     
                                      </div>
                                    </div>
                            {li.hotel_taxes.map((data, index) => {
                              return (
                                <>
                                  <span
                                    className="room_info_count mb-1"
                                    style={{ marginTop: "3%" }}
                                  >
                                    <span className="room_sect_no be_form_roomsec_1">
                                      {index + 1}
                                    </span>
                                  </span>
                                  <span className="col-md-4">
                                    <div className="mb-4">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Tax Name{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <span className="info-tag">
                                          i
                                          <span className="tooltiptext">
                                            eg: Vat 5%
                                          </span>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        onChange={(e) =>
                                          handleChangetax(i, index, e)
                                        }
                                        value={data.title}
                                        name="title"
                                      />
                                      {formerror.map((err, i) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.tax_name ? (
                                              <label style={{ color: "red" }}>
                                                {err.tax_name}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </span>
                                  <span className="col-md-3">
                                    <div className="mb-4">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Rate
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <div class="info-tag-new">i</div>
                                        <span className="tooltiptext-new">
                                          eg: rate 5% or 100 {li.currency?.code}
                                        </span>
                                      </label>
                                      <input
                                        type="number"
                                        onKeyDown={(e) =>
                                          symbolsrate.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        className="form-control"
                                        placeholder=""
                                        id="emailidInput"
                                        onChange={(e) =>
                                          handleChangetax(i, index, e)
                                        }
                                        value={data.value}
                                        name="value"
                                        min={0}
                                        onFocus={(e) =>
                                          e.target.addEventListener(
                                            "wheel",
                                            function (e) {
                                              e.preventDefault();
                                            },
                                            { passive: false }
                                          )
                                        }
                                      />

                                      {formerror.map((err, i) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.tax_dis ? (
                                              <label style={{ color: "red" }}>
                                                {err.tax_dis}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                      <span className="be_form_tax_info_label_10 tax_discount_type_section">
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          onChange={(e) =>
                                            handleChangetax(i, index, e)
                                          }
                                          value={data.amount_type}
                                          name="amount_type"
                                        >
                                          <option value="percentage" selected>
                                            %
                                          </option>
                                          <option value="currency">
                                            {li.currency?.code}
                                          </option>
                                        </select>
                                      </span>
                                    </div>
                                  </span>

                                  <span className="col-md-2">
                                    <div className="mb-4">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Applicable to
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <span className="info-tag">
                                          i
                                          <span className="tooltiptext">
                                            eg: 5% discount per person or 5%
                                            discount per room
                                          </span>
                                        </span>
                                      </label>
                                      <select
                                        id="ForminputState"
                                        className="form-select"
                                        onChange={(e) =>
                                          handleChangetax(i, index, e)
                                        }
                                        value={data.increment_type}
                                        name="increment_type"
                                      >
                                        <option value="">Choose</option>
                                        <option value="per_person">
                                          Per Person
                                        </option>
                                        <option value="per_room">
                                          Per Room
                                        </option>
                                      </select>
                                      {/* <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      onChange={(e) =>
                                        handleChangetax(i, index, e)
                                      }
                                      value={data.increment_type}
                                      name="increment_type"
                                    /> */}
                                      {formerror.map((err, i) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.increment_type ? (
                                              <label style={{ color: "red" }}>
                                                {err.increment_type}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </span>
                                  <span className="col-md-3">
                                    <div className="mb-4">
                                      <label
                                        for="firstNameinput"
                                        className="form-label be_form_label"
                                      >
                                        Room type
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                        <div class="info-tag-new">i</div>
                                        <span className="tooltiptext-new">
                                          This tax is applied based on the room
                                          type selected
                                        </span>
                                      </label>
                                      <MultiSelect
                                        options={li.hotel_rooms}
                                        value={data.roomtax_info}
                                        onChange={(e) =>
                                          handleChangeesroomtypetax(
                                            i,
                                            index,
                                            e,
                                            "roomtax_info"
                                          )
                                        }
                                        labelledBy="Select"
                                        selectionType="counter"
                                        closeOnChangedValue={false}
                                        aria-expanded="true"
                                      />

                                      {formerror.map((err, i) => {
                                        return (
                                          <>
                                            {index === i &&
                                            err &&
                                            err.roomtax_info ? (
                                              <label style={{ color: "red" }}>
                                                {err.roomtax_info}
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </span>
                                  <div
                                    className={
                                      index === li.hotel_taxes.length - 1
                                        ? "row mt-1"
                                        : "row mt-1 sep_tax_grp"
                                    }
                                    style={{ paddingBottom: "10px" }}
                                  >
                                    <span className="col-md-2">
                                      <div className="mb-4">
                                        <label
                                          for="firstNameinput"
                                          className="form-label be_form_label"
                                        >
                                          Frequency
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                          {/* <span className="info-tag">
                                          i
                                          <span className="tooltiptext">
                                            eg: 5% discount per person or 5%
                                            discount per room
                                          </span>
                                        </span> */}
                                        </label>
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          onChange={(e) =>
                                            handleChangetax(i, index, e)
                                          }
                                          value={data.increment_frequency}
                                          name="increment_frequency"
                                        >
                                          <option value="">Choose</option>
                                          <option value="once">Once</option>
                                          <option value="per_night">
                                            Per Night
                                          </option>
                                        </select>

                                        {formerror.map((err, i) => {
                                          return (
                                            <>
                                              {index === i &&
                                              err &&
                                              err.increment_frequency ? (
                                                <label style={{ color: "red" }}>
                                                  {err.increment_frequency}
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </span>
                                    <span
                                      className="col-md-2"
                                      style={{ left: "3%" }}
                                    >
                                      <div className="mb-4">
                                        <div>
                                          <label
                                            for="firstNameinput"
                                            className="form-label be_form_label"
                                          >
                                            Service Charge
                                            {/* <span className="form-validation-required">
                                            *
                                          </span> */}
                                          </label>
                                        </div>
                                        <div
                                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                          dir="ltr"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                                            id="inlineswitch5"
                                            name="service_charge_status"
                                            value={data.service_charge_status}
                                            checked={
                                              data.service_charge_status ===
                                              true
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handleChangetogghotel(
                                                i,
                                                index,
                                                e,
                                                "service_charge_status"
                                              )
                                            }
                                            style={{
                                              width: "50px",
                                              height: "25px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </span>
                                    <span className="col-md-2">
                                      <div className="mb-4">
                                        <label
                                          for="firstNameinput"
                                          className="form-label be_form_label"
                                        >
                                          Service Charge Applicable
                                          {/* <span className="form-validation-required">
                                            *
                                          </span> */}
                                        </label>

                                        <span
                                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                          dir="ltr"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                                            id="inlineswitch5"
                                            name="service_charge_applicable"
                                            value={
                                              data.service_charge_applicable
                                            }
                                            checked={
                                              data.service_charge_applicable ===
                                              true
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handleChangetogghotel(
                                                i,
                                                index,
                                                e,
                                                "service_charge_applicable"
                                              )
                                            }
                                            style={{
                                              width: "50px",
                                              height: "25px",
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </span>
                                    <span className="col-md-6">
                                      <div className="mb-4">
                                        <label
                                          for="firstNameinput"
                                          className="form-label be_form_label"
                                        >
                                          Tax Description
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                          <span className="info-tag">
                                            i
                                            <span className="tooltiptext">
                                              {data?.service_charge_applicable ===
                                              true ? (
                                                <>
                                                  eg: vat 5% must be apply to
                                                  the room rate + 10% service
                                                  charges
                                                </>
                                              ) : (
                                                <>
                                                  eg: vat 5% must be apply to
                                                  the room rate
                                                </>
                                              )}
                                            </span>
                                          </span>
                                        </label>

                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          id="emailidInput"
                                          name="message"
                                          value={data.message}
                                          onChange={(e) =>
                                            handleChangetax(i, index, e)
                                          }
                                        />
                                        {formerror.map((err, i) => {
                                          return (
                                            <>
                                              {index === i &&
                                              err &&
                                              err.tax_comment ? (
                                                <label style={{ color: "red" }}>
                                                  {err.tax_comment}
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </span>

                                    <div className="col-lg-4">
                                      <div className="input-group">
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                          style={{ width: "130px" }}
                                        >
                                          Choose Language:
                                        </label>
                                        <div className="col-md-7">
                                          <MultiSelect
                                            options={be_language}
                                            value={data.multi_lang}
                                            onChange={(e) =>
                                              handleChangeesLangtax(
                                                i,
                                                index,
                                                e,
                                                "multi_lang"
                                              )
                                            }
                                            labelledBy="Select"
                                            selectionType="counter"
                                            allowCustomValue={true}
                                            aria-expanded="true"
                                            hasSelectAll={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="appendmore"></div>
                                    {data.multi_lang.length > 0 &&
                                      data.multi_lang.map((element, ind) => (
                                        <div
                                          key={ind}
                                          className="col-md-12 translation-fill-section"
                                          id={`lang_${element.value}`}
                                          data-value="1"
                                          style={{ marginTop: "20px" }}
                                        >
                                          <div className="">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-4">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Tax Name
                                                  <span className="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={element.name}
                                                  name="name"
                                                  onChange={(e) =>
                                                    handleLang(i, index, ind, e)
                                                  }
                                                  //  onChange={(e) => setMulti((prevMulti) =>prevMulti.map((item, i) =>
                                                  //        i === index ? { ...item, name: e.target.value } : item
                                                  //      )
                                                  //    )
                                                  //  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg-8">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Tax Description
                                                  <span className="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={element.description}
                                                  name="description"
                                                  onChange={(e) =>
                                                    handleLang(i, index, ind, e)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    <div
                                      className="col-md-1"
                                      style={{ bottom: "15px" }}
                                    >
                                      <span
                                        className={
                                          index === li.hotel_taxes.length - 1
                                            ? "extrarate-room-link extra_link_padding"
                                            : "extrarate-room-link d-none"
                                        }
                                      >
                                        <Link
                                          className="extra-rate-add"
                                          to="#"
                                          onClick={(e) => addInputFieldtax(i)}
                                        >
                                          +
                                        </Link>
                                      </span>

                                      <Link
                                        className={
                                          index === 0
                                            ? "remove_more d-none"
                                            : "remove_more extra_link_padding"
                                        }
                                        to="#"
                                        onClick={(e) =>
                                          removeInputFieldstax(i, index)
                                        }
                                      >
                                        X
                                      </Link>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {paginate > 1 ? (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={paginate}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Taxedit;
