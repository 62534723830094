import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Alerts from '../../Alerts';
import Commonheader from '../../Common/Commonheader';
import Footer from '../../Common/Footer';
import Config from '../../Config';
import DeleteModal from '../../DeleteModal';
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { FaArrowTurnDown } from "react-icons/fa6";
import Nodatafound from '../../Common/Nodatafound';
import Loading from '../../Loading';


function Temporaryhotel(props) {
  console.log(props);
  const content = props?.location?.state ? props.location.state.content : null;

    const [list,setList]=useState([]);
    const [alertmessage, setAlertmessage] = useState("");
    const [alerttype, setAlerttype] = useState("");
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false);
  const [paginate,setPaginate]=useState("");
  const language = localStorage.getItem("be_language");
  const[page,setPage]=useState(1);
  const[pageindex,setPageindex]=useState(1);
  const [listgroup,setListgroup]=useState([]);
  const[pagegroup,setPagegroup]=useState(1);
  const[pageindexgroup,setPageindexgroup]=useState(1);
  const [paginategroup,setPaginategroup]=useState("");
const [pageid,setPageid]=useState("");
const [pageid1,setPageid1]=useState("");
const [popup,setPopup]=useState(false);
const [sendid,setSendid]=useState("");
const [email,setEmail]=useState("");

const [popupreview,setPopupreview]=useState(false);
const [sendidreview,setSendidreview]=useState("");
const [emailreview,setEmailreview]=useState("");
const [loader,setLoader]=useState(false);

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [modal_animationFlip1, setmodal_animationFlip1] = useState(false);

  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(id);

  }
  function tog_animationFlip1(id) {
    setmodal_animationFlip1(!modal_animationFlip1);
    setPageid1(id);

  }

  const [status,setStatus]=useState({
    index:"",
    sta:false,
  })

  const [tab,setTab]=useState(content && content==="group"?"group":"individual");
    const gethotel = async (e) => {

        const res = await fetch(`${Config.apiurl}temporary-hotel/list?page=1`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
    
          },
        });
        const data1 = await res.json();
        const data = data1.data;
        if (data1.status_code !== "200" || !data) {
          console.log("error");
        } else {
          setList(data.data);
          setPaginate(data.last_page)
          setLoader(true);
        }
      }
      const fetchComments = async (currentPage) => {
          const res = await fetch(`${Config.apiurl}temporary-hotel/list?page=${currentPage}`, {
            method: "POST",
    
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
    
        const data = await res.json();
        return data.data.data;
      
        
      };
    
      const handlePageClick = async (data) => {
        if(data===1){
          setPage(data);
          setPageindex(1);
          const commentsFormServer = await fetchComments(data);
        
          setList(commentsFormServer);
        
        }
        else{
          let currentPage = data.selected + 1;
          setPageindex(currentPage);
          setPage(data);
          const commentsFormServer = await fetchComments(currentPage);
        
          setList(commentsFormServer);
        }
      };

      const gethotelgroup = async (e) => {

        const res = await fetch(`${Config.apiurl}temporary-hotel/list/group?page=1`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
    
          },
        });
        const data1 = await res.json();
        const data = data1.data;
        if (data1.status_code !== "200" || !data) {
          console.log("error");
        } else {
          setListgroup(data.data);
          setPaginategroup(data.last_page)
          setLoader(true);
        }
      }
      const fetchCommentsgroup = async (currentPage) => {
          const res = await fetch(`${Config.apiurl}temporary-hotel/list/group?page=${currentPage}`, {
            method: "POST",
    
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
    
        const data = await res.json();
        return data.data.data;
      
        
      };
    
      const handlePageClickgroup = async (data) => {
        console.log("hiiii");
        if(data===1){
          setPagegroup(data);
          setPageindexgroup(1);
          const commentsFormServer = await fetchCommentsgroup(data);
        
          setListgroup(commentsFormServer);
        
        }
        else{
          let currentPage = data.selected + 1;
          setPageindexgroup(currentPage);
          setPagegroup(data);
          const commentsFormServer = await fetchCommentsgroup(currentPage);
        
          setListgroup(commentsFormServer);
        }
      };
      useEffect(() => {
        gethotel();
        gethotelgroup();
      },[]
      )

      function rowclick(i){
        let newval = [...listgroup];
        listgroup[i]["disp_status"] =listgroup[i]["disp_status"]? !listgroup[i]["disp_status"]:true;
        setListgroup(newval);
      }


      const DeleteData = async (id) => {

        const res = await fetch(`${Config.apiurl}temporary-hotel/delete/group/${id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        const data = await res.json();
        
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
              setmodal_animationFlip(false);
              handlePageClickgroup(pagegroup)
            } else {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              setmodal_animationFlip(false);
              handlePageClickgroup(pagegroup)

            }
          // });
      };
      const DeleteDatain = async (id) => {

        const res = await fetch(`${Config.apiurl}temporary-hotel/delete/${id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        const data = await res.json();
        
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
              setmodal_animationFlip1(false);
              handlePageClick(page);
            } else {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              setmodal_animationFlip1(false);
              handlePageClick(page);

            }
          // });
      };

      const sendemail= async (id,email) => {
        setPopup(true);
        setSendid(id);
        setEmail(email);
      }
      const SendEmailapprove= async () => {
        const res = await fetch(`${Config.apiurl}temporary-hotel/link-send/${sendid}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        const data = await res.json();
         
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              setPopup(false);

            }
         
      };
      


      const sendemailreview= async (id,email) => {
        setPopupreview(true);
        setSendidreview(id);
        setEmailreview(email);
      }
      const SendEmailapprovereview= async () => {
        const res = await fetch(`${Config.apiurl}temporary-hotel/review-request-send/${sendidreview}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        const data = await res.json();
         
            if (data.status_code !== "200" || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
              setPopupreview(false);

            }
         
      };
      const sendApprove= async (id,type) => {
        console.log(type);
        const res = await fetch(`${Config.apiurl}temporary-hotel/acepted/${id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        const data = await res.json();
         
            if (data.status_code !== 200 || !data) {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("error");
            } else {
              setAlert(true);
              setAlertmessage(data.message);
              setOpen(true);
              setAlerttype("success");
                if(type==="group"){
                  handlePageClickgroup(pagegroup)

                }
                else{
                  handlePageClick(page);

                }
            }
       
      };
      console.log(tab);
  return (
    <div id="layout-wrapper">
     <Commonheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
           <div className='row temp_row'>
            <div className="page-title-box page_title_new d-sm-flex align-items-center justify-content-between">
                  
                  <h4 className="db-page-title">Hotel Creation</h4>
                 <div className="button-style">
                        <Link
                          type="button"
                          to="/temporary-property-create"
                          className="btn db-save-button "
                        >
                          Create
                        </Link>
                      </div>
                      </div>
                      </div>
                 
                                  {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className='row'>
            <div className="tabs-link-temp">
                    <div className="temp-tab">
                      <button
                      type="button"
                        className={
                          tab === "individual" ? "tablinks active" : "tablinks"
                        }
                        id="tabBasicInfo"
                         onClick={(e) => setTab("individual")}
                      >
                       Individual hotel
                      </button>
                      <button
                      type="button"
                        className={
                          tab === "group" ? "tablinks active" : "tablinks"
                        }
                        id="tabRoomInfo"
                          onClick={(e) => setTab("group")}
                      >
                       Group hotel
                      </button>
                    
                    </div>
                  </div>
            </div>
            {tab==="individual" && (content==="individual" ||content===null)?
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                 
                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        >
                         
                        </ul>
                        {loader===false?
                        <Loading/>
                        :
                        <>
                      {list.length>0?
                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                {/* <th scope="col" style={{ width: "25px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                  
                                    />
                                  </div>
                                </th> */}
                                <th className="" data-sort="id">
                                  Sl No.
                                </th>
                                <th className="" data-sort="customer_name">
                                  Hotel
                                </th>
                                <th>Resend Email</th>
                                <th className="" data-sort="customer_name">
                                  Status
                                </th>
                                <th></th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {list.map((user, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.hotel_name}
                                    </td>
                                    {user.hotel_form_status === 0 || (user.hotel_form_status===1 && user.hotel_info_status===false)?
                                    <td><button type="button" className='send_email_btn' onClick={(e)=>sendemail(user.hotel_id,user.registration_email)}>Resend Email</button></td>
                                    :user.hotel_form_status === 3?
                                    <td><button type="button" className='send_email_btn' onClick={(e)=>sendemailreview(user.hotel_id,user.registration_email)}>Resend Review Email</button></td>
                                    :
                                <td></td>
                                }
                                    <td className="status">
                                      {user.hotel_form_status === 0 ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          Create Hotel
                                        </span>
                                      ) : user.hotel_form_status === 1?
                                        <span className="badge badge-soft-success text-uppercase">
                                          Link Sent
                                        </span>
                                      : user.hotel_form_status === 2?
                                      <span className="badge badge-soft-success text-uppercase">
                                       User Update
                                       </span>
                                      : user.hotel_form_status === 3?
                                       <span className="badge badge-soft-success text-uppercase">
                                     Form Review Request Sent
                                       </span>
                                      : user.hotel_form_status === 4?
                                       <span className="badge badge-soft-success text-uppercase">
                                     Form Review Response Received
                                       </span>
                                       : user.hotel_form_status === 5?
                                       <span className="badge badge-soft-success text-uppercase">
                                    Complete
                                       </span>
                                       :
                                       user.hotel_form_status === 6?
                                       <span className="badge badge-soft-danger text-uppercase">
                                    Cancelled
                                       </span>
                                       :""
                              }
                                    </td>
                                    {user.hotel_form_status ===2? 
                                    // <td><button className='temp_approve_btn' onClick={(e)=>sendApprove(user.hotel_id,"individual")}>Approve</button>
                                  <td>
                                    <Link className='temp_approve_btn' to={`/temporary-property-view/${user.hotel_id}`} 
                                    // state={{
                                    //   hotel_id:user.hotel_id,
                                    //   string_id:user.identity_string
                                    // }}
                                    >Check & Approve</Link>

        
                                    </td>
                                    // :user.hotel_form_status === 5?
                                    // <td><button className='temp_approve_btn' >Complete</button></td>
                                    :user.hotel_form_status=== 2 || (user.hotel_form_status ===1 && user.hotel_info_status===false)  || user.hotel_form_status===0?
                                    <td></td>
                                    :
                                    <td><Link className='temp_approve_btn' to={`/temporary-property-view/${user.hotel_id}`}
                                    //  state={{
                                    //   hotel_id:user.hotel_id,
                                    //   string_id:user.identity_string
                                    // }}
                                    >View</Link></td>
                                    
                                    }
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                           to={`/temporary-property-edit/${user.hotel_id}/individual`}
                                          //  state={{
                                          //   hotel_id: user.hotel_id,
                                          //   type:"individual"
                                          //  }}
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() =>
                                              tog_animationFlip1(user.hotel_id)
                                            }
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        :
                        <Nodatafound/>
                      }
                      </>
                    }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-body pt-0">
                    <div>
                      <ul
                        className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                        role="tablist"
                      >
                      </ul>
                      <div className="table-responsive table-card mb-1">
                        <table
                          className="table table-nowrap align-middle new_table_style"
                          id="orderTable"
                        >
                          <thead className="text-muted table-light">
                            <tr className="text-uppercase">
                              {/* <th scope="col" style={{ width: "25px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                
                                  />
                                </div>
                              </th> */}
                              <th className="temp_hotel_sl" data-sort="id">
                                Sl No.
                              </th>
                              <th className="temp_hotel_group" data-sort="customer_name" >
                               Group
                              </th>
                             
                              <th className="temp_hotel_email">Resend Email</th>
                              <th className="temp_hotel_status" data-sort="customer_name" >
                                Status
                              </th>
                             <th  className="temp_hotel_approve">View</th>
                              <th className="temp_hotel_action" data-sort="city">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {listgroup.map((user, index) => {
                              return (
                                <>
                                <tr key={index} className= {user.disp_status && user.disp_status===true ? "main_row":""}>
                                  <td className="id temp_hotel_sl">{pageindexgroup===1 ? index+1: (pageindexgroup-1) * 10 + index +1}</td>
                                 
                                  <td className="expand-column temp_hotel_group" >
                                {user.name}    {""}
                                <span className="row_expand_hotel" onClick={(e)=>rowclick(index)}><FaArrowTurnDown/></span>
                                       
                                 </td>
                                 {/* <td> <span
                                    className="temp_expand" onClick={(e)=>rowclick(index)}>+</span></td> */}

                                  <td className='temp_hotel_email'></td>
                                  <td className='status temp_hotel_status'></td>
                                  <td className='temp_hotel_approve'></td>
                                  <td className="temp_hotel_action" >
                                    <ul className="list-inline hstack gap-2 mb-1">
                                      <li
                                        className="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <Link
                                         to={`/temporary-property-edit/${user.group_id}/group`}
                                        //  state={{
                                        //   hotel_id:user.group_id,
                                        //   type:"group"
                                        //  }}
                                          data-bs-toggle="modal"
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>
                                      <li
                                        className="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Remove"
                                      >
                                        <Link
                                          className="text-danger d-inline-block remove-item-btn"
                                          data-bs-toggle="modal"
                                          to="#"
                                          onClick={() =>
                                            tog_animationFlip(user.group_id)
                                          }
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </td>
                                 
                                </tr>
                                {user.hotels.map((hte,i)=>{
                                   return(
                                <tr className={user.disp_status && user.disp_status===true? user.hotels.length-1===i? "row_sub":'row_display':"d-none"} key={i}>
                               
                                  <td className="id temp_hotel_sl"></td> 
                                 
                                <td className="temp_inside_table temp_hotel_group" >{hte.hotel_name}</td>
                                
                                {hte.hotel_form_status === 0 || (hte.hotel_form_status===1 && hte.hotel_info_status===false)?
                                <td className="temp_hotel_email"><button  type="button" className='send_email_btn' onClick={(e)=>sendemail(hte.hotel_id,hte.registration_email)}>Resend Email</button></td>
                                :
                                hte.hotel_form_status === 3?
                                <td className="temp_hotel_email"><button  type="button" className='send_email_btn' onClick={(e)=>sendemailreview(hte.hotel_id,hte.registration_email)}>Resend Review Email</button></td>

                                :

                                <td className="temp_hotel_email"></td>
                                }
                                <td className="status temp_hotel_status">
                                  {hte.hotel_form_status === 0 ? (
                                  <span class="badge badge-soft-danger text-uppercase">
                                  {" "}
                                  Create Hotel
                                  </span>
                                  ) : hte.hotel_form_status === 1?
                                  <span className="badge badge-soft-success text-uppercase">
                                  Link Sent
                                  </span>
                                  : hte.hotel_form_status === 2?
                                  <span className="badge badge-soft-success text-uppercase">
                                  User Update
                                  </span>
                                  : hte.hotel_form_status === 3?
                                  <span className="badge badge-soft-success text-uppercase">
                                  Form Review Request Sent
                                  </span>
                                  : hte.hotel_form_status === 4?
                                  <span className="badge badge-soft-success text-uppercase">
                                  Form Review Response Received
                                  </span>
                                  : hte.hotel_form_status === 5?
                                  <span className="badge badge-soft-success text-uppercase">
                                  Complete
                                  </span>
                                  :
                                  hte.hotel_form_status === 6?
                                  <span className="badge badge-soft-danger text-uppercase">
                                  Cancelled
                                  </span>
                                  :""
                                  }
                                </td>  
                              
                                <td className='temp_hotel_approve'>
                                {hte.hotel_form_status === 2?
                                  <Link className='temp_approve_btn' to={`/temporary-property-view/${hte.hotel_id}`}
                                  //  state={{
                                  //                                       hotel_id:hte.hotel_id,
                                  //                                       string_id:hte.identity_string
                                  //                                     }}
                                  >Check & Approve</Link>
                                // :hte.hotel_form_status === 5?

                                // <Link className='temp_approve_btn' to={`/temporary-property-view/${hte.hotel_id}`}
                               
                                //   >Check & Approve</Link>
                                  :hte.hotel_form_status=== 2 || (hte.hotel_form_status ===1 && hte.hotel_info_status===false)|| hte.hotel_form_status===0?
                                  ""
                                  :<Link className='temp_approve_btn' to={`/temporary-property-view/${hte.hotel_id}`}
                                  //  state={{
                                  //   hotel_id:hte.hotel_id,
                                  //   string_id:hte.identity_string
                                  // }}
                                  >View</Link>

                                }
                                </td>

                                                                        
                                <td className="temp_hotel_action" > </td>                                       
                                                                       
                                </tr>
                                  )})} 
                                </>
                              );
                            })}
                          </tbody>
                        </table>

                        {paginategroup > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginategroup}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClickgroup}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
}
          </div>
        </div>

        <Footer />
      </div>
      {modal_animationFlip ? (
        <DeleteModal
          modal_animationFlip={modal_animationFlip}
          tog_animationFlip={tog_animationFlip}
          pageid={pageid}
          DeleteData={DeleteData}
          message={"Hotel group"}
        />
      ) : (
        ""
      )}


{modal_animationFlip1 ? (
        <DeleteModal
          modal_animationFlip={modal_animationFlip1}
          tog_animationFlip={tog_animationFlip1}
          pageid={pageid1}
          DeleteData={DeleteDatain}
          message={"Hotel"}
        />
      ) : (
        ""
      )}


{popup===true?
  <Modal
      id="flipModal"
      isOpen={popup}
      toggle={() => {
        setPopup(false);
      }}
      modalClassName="flip"
      centered
      style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
    >
      <ModalBody className="modal-body p-5 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#405189,secondary:#f06548"
          style={{ width: "90px", height: "90px" }}
        ></lord-icon>

        <div className="mt-4 text-center">
          <h4>Email address is {email} </h4>
          <p className="text-muted fs-15 mb-4">
           Do you want to resend email to {email}?
          </p>
        </div>

        <button
        type="button"
          className="btn btn-link link-danger fw-medium text-decoration-none"
          onClick={() => {
            setPopup(false);
          }}
        >
          {" "}
          <i className="ri-close-line me-1 align-middle"></i>
          Close{" "}
        </button>
        <Button color="success" onClick={SendEmailapprove}>
          {" "}
          Yes, Resend email{" "}
        </Button>
      </ModalBody>
    </Modal>
    :""}

{popupreview===true?
  <Modal
      id="flipModal"
      isOpen={popupreview}
      toggle={() => {
        setPopupreview(false);
      }}
      modalClassName="flip"
      centered
      style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
    >
      <ModalBody className="modal-body p-5 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#405189,secondary:#f06548"
          style={{ width: "90px", height: "90px" }}
        ></lord-icon>

        <div className="mt-4 text-center">
          <h4>Email address is {emailreview} </h4>
          <p className="text-muted fs-15 mb-4">
           Do you want to resend review email to {emailreview}?
          </p>
        </div>

        <button
        type="button"
          className="btn btn-link link-danger fw-medium text-decoration-none"
          onClick={() => {
            setPopupreview(false);
          }}
        >
          {" "}
          <i className="ri-close-line me-1 align-middle"></i>
          Close{" "}
        </button>
        <Button color="success" onClick={SendEmailapprovereview}>
          {" "}
          Yes, Resend email{" "}
        </Button>
      </ModalBody>
    </Modal>
    :""}
    </div>
  )
}

export default Temporaryhotel
