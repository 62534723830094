import React, { useContext, useEffect, useState } from "react";
import Commonheader from "../../Common/Commonheader";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import Loading from "../../Loading";
import Toastmodal from "../../Common/Toastmodal";
import Commonimagepath from "../../Commonimagepath";
import { UserContext } from "../../Contexts/UserContext";
import { Modal, ModalBody } from "reactstrap";
import { useAuthHandling } from "../../Common/useAuthHandling";

function Offers() {
  const [offerlist, setOfferlist] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  const [loader, setLoader] = useState(true);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [offersugg, setOffersugg] = useState([
    {
      offername: "Valentines Day Offer",
      slug: "valentine",
    },
    {
      offername: "3 Night Offer",
      slug: "3-night",
    },
    {
      offername: "Eid Official Website Offer",
      slug: "eid",
    },
  ]);
  const [offerid, setOfferid] = useState("");
  const [status, setStatus] = useState("");
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

  function tog_animationFlip(id,status) {
    setOfferid(id);
    setStatus(status);
    setmodal_animationFlip(!modal_animationFlip);
  }

  const getlist = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/offers/list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLoader(false);
        setOfferlist(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlist();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlist();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setLoader(false);
    //   setOfferlist(data.data);
    // }
  };

  const duplicate = async (offerid) => {
    const res = await fetch(`${Config.apiurl}hotel/offer/duplicate-copy`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        offer_id: offerid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
          setToastmodal(true);
          setToastmessage("Offer duplicate added successfully");
          setToasttype("success");
          setToastlarge(true);
          getlist();
      } else if (data.reauth === true) {
        handleUnauthorized();
        duplicate();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      duplicate();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setToastmodal(true);
    //   setToastmessage("Offer duplicate added successfully");
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getlist();
    // }
  };

  const StatusChange = async () => {
    const res = await fetch(`${Config.apiurl}hotel/offer/change-status`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        offer_id: offerid,
        status: status,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status === "200") {
        setmodal_animationFlip(false);
        getlist();
      } else if (data.reauth === true) {
        handleUnauthorized();
        StatusChange();
      } else {
        console.log("error");
        setmodal_animationFlip(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      StatusChange();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setmodal_animationFlip(false);
    //   getlist();
    // }
  };

  useEffect(() => {
    getlist();
  }, [id]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="offer-list-main">
              <div className="offer-list-top">
                <div className="offer-heading">
                  <h4 className="">{hotelname}</h4>
                  <h6 className="offer-list-head" style={{ color: "#008080" }}>
                    Recently Created Offers
                  </h6>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                      <Link
                        type="button"
                        to={`/offers-create/${id}`}
                        className="btn db-save-button offer-save"
                      >
                        Create New Offer
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {loader === true ? (
                  <Loading />
                ) : (
                  <>
                    {offerlist.length > 0 ? (
                      offerlist.map((offer, i) => {
                        return (
                          <div className="col-md-12" key={i}>
                            <div className="card offer-lists">
                              <div className="offer-list-items offer-padding">
                                <div>
                                  <Link
                                    to={`/offer/view/${offer.offer_id}/${id}`}
                                  >
                                    <div className="offer-image">
                                      {offer.offer_image ? (
                                        <img
                                          className="offer_list_img"
                                          src={`${Commonimagepath.apiurl}${offer.offer_image}`}
                                        />
                                      ) : (
                                        <img src="/images/icon/price-tag.png" />
                                      )}
                                      {offer.promocode_status === true ? (
                                        <span
                                          className={
                                            offer.offer_image
                                              ? "confidential-icon_1"
                                              : "confidential-icon"
                                          }
                                        >
                                          <img src="/images/icon/padlock.png" />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="offer-heading new-year-offer">
                                      <Link
                                        to={`/offer/view/${offer.offer_id}/${id}`}
                                      >
                                        <h6 className="offer_head">
                                          {offer.title}
                                        </h6>
                                        <p className="offer-code">
                                          ({offer.offer_key})
                                        </p>
                                        {offer.price_title &&
                                        offer.price_title !== "undefined" &&
                                        offer.price_title !== null &&
                                        offer.price_title !== "null" ? (
                                          <p className="offer-discount">
                                            {offer.price_title?.length > 20
                                              ? `${offer.price_title?.slice(
                                                  0,
                                                  20
                                                )}...`
                                              : offer.price_title}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </Link>
                                    </div>
                                  </Link>
                                </div>
                                <div className="offer-occupancies">
                                  {offer.room_data.map((room, indx) => {
                                    return (
                                      <>
                                        {room.label} ({room.room_key}){" "}
                                        {offer.room_data.length === indx + 1
                                          ? ""
                                          : ", "}
                                      </>
                                    );
                                  })}
                                </div>
                                <div className="offer-status-button offer-btn-direction">
                                  {/* {offer.status === true ? (
                                    <Link
                                      to="#"
                                      className="offer-status-enabled"
                                      onClick={()=>tog_animationFlip(offer.offer_id, 0)}
                                      style={{background:"#a4e785",borderColor:"#d2d2d2"}}
                                     
                                    >
                                      Enabled
                                    </Link>
                                  ) : (
                                    <Link
                                      to="#"
                                      className="offer-status-enabled"
                                      onClick={()=>tog_animationFlip(offer.offer_id, 1)}
                                      style={{background:"#fe8c8c",borderColor:"#d2d2d2"}}
                                    >
                                      Disabled
                                    </Link>
                                  )} */}
                                      <div
                                    className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                    dir="ltr"
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                                      id="inlineswitch5"
                                      style={{ width: "50px", height: "25px" }}
                                      value={offer.status}
                                      checked={offer.status === true ? true : false}
                                      onChange={(e) =>
                                        tog_animationFlip(offer.offer_id, offer.status === true ? 0 : 1)
                                      }
                                    />
                                  </div>
                                </div>
                                {/* <div className="offer-status-button offer-btn-direction">
                                  {offer.status === true ? (
                                    <Link
                                      to={`/offer/view/${offer.offer_id}/${id}`}
                                      className="offer-status-enabled"
                                    >
                                      Enabled
                                    </Link>
                                  ) : (
                                    <Link
                                      to={`/offer/view/${offer.offer_id}/${id}`}
                                      className="offer-status-enabled"
                                    >
                                      Draft
                                    </Link>
                                  )}
                                </div> */}
                                <span className="offer-links">
                                  <Link
                                    className="offer-duplicate-icon"
                                    to={`/offer/view/${offer.offer_id}/${id}`}
                                    title="Edit"
                                    style={{ top: "4px" }}
                                  >
                                    <i className="ri-pencil-fill fs-16"></i>
                                  </Link>
                                  <Link
                                    className="offer-duplicate-icon"
                                    to=""
                                    title="Duplicate"
                                    onClick={(e) => duplicate(offer.offer_id)}
                                  >
                                    <img src="/images/icon/copy.png" />
                                  </Link>
                                  {/* <Link
                                    className="offer-delete-icon"
                                    to=""
                                    title="Delete"
                                  >
                                    <img src="/images/trash.png" />
                                  </Link> */}
                                  <Link
                                    className="offer-export-icon"
                                    to=""
                                    title="Copy link"
                                  >
                                    <img src="/images/icon/hyperlink.png" />
                                  </Link>
                                </span>
                              </div>
                              <div className="offer-border-bottom"></div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="card-body pt-0">
                        <div className="no-content text-center">
                          No data Found
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="offer_suggestion">
                  <div className="offer-heading">
                    <h6 className="offer-list-head mt-3">Suggestions</h6>
                  </div>
                </div>
                {offersugg.map((sugg, i) => {
                  return (
                    <div className="col-md-12" key={i}>
                      <div className="card offer-lists">
                        <div className="offer-list-items">
                          <div>
                            <Link to={`/offer/view-sugg/${sugg.slug}/${id}`}>
                              <div className="offer-image">
                                {}
                                <img src="/images/icon/price-tag.png" />
                              </div>
                              <div className="offer-heading">
                                <Link
                                  to={`/offer/view-sugg/${sugg.slug}/${id}`}
                                >
                                  <h6 className="offer_head offer-full-wi">
                                    {sugg.offername}
                                  </h6>
                                </Link>
                              </div>
                            </Link>
                          </div>

                          <div className="offer-status-button">
                            <Link
                              to={`/offer/view-sugg/${sugg.slug}/${id}`}
                              className="offer-status-enabled"
                            >
                              Add
                            </Link>
                          </div>
                        </div>
                        <div className="offer-border-bottom"></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          id="flipModal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              Do you want to {status===0? "disable": "enable" } this offer?
            </div>
            {/* <button
              className="offer_disable_pop_cancel" onClick={()=>setmodal_animationFlip(false)}>
             Cancel
            </button> */}
             <button
                className="btn btn-link fw-medium text-decoration-none"
                style={{ color: "#FF7979" }}
                onClick={()=>setmodal_animationFlip(false)}
              >
                {" "}
                <i className="ri-close-line me-1 align-middle"></i>
                Close{" "}
              </button>
            <button
              className="offer_disable_pop_yes"
              onClick={StatusChange}
           >
             Yes
            </button>
          </ModalBody>
        </Modal>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Offers;
