import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import Nodatafound from "../../Common/Nodatafound";
import Toastmodal from "../../Common/Toastmodal";
import Commonimagepath from "../../Commonimagepath";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import Loading from "../../Loading";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { Modal, ModalBody } from "reactstrap";

function Extras() {
  const [extraList, setExtraList] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  const [loader, setLoader] = useState(true);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const handleExtraToggle = (isChecked, name, extraIndex, extraid) => {
    const updatedExtraList = [...extraList];
    updatedExtraList[extraIndex][name] = isChecked;
    setExtraList(updatedExtraList);
    getpreStay(extraid, extraIndex);
  };

  const handleExtraToggleemail = (isChecked, name, extraIndex, extraid) => {
    const updatedExtraList = [...extraList];
    updatedExtraList[extraIndex][name] = isChecked;
    setExtraList(updatedExtraList);
    getEmail(extraid, extraIndex);
  };

  const [extraid, setExtraid] = useState("");
  const [status, setStatus] = useState("");
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

  function tog_animationFlip(id,status) {
    setExtraid(id);
    setStatus(status);
    setmodal_animationFlip(!modal_animationFlip);
  }  

  const getlist = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/extras/list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setExtraList(data.data);
        setLoader(false);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlist();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlist();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setLoader(false);
    //   setExtraList(data.data);
    // }
  };

  const getpreStay = async (extraid, extraIndex) => {
    const res = await fetch(`${Config.apiurl}hotel/extra/pre-stay/change`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        extra_id: extraid,
        pre_stay_status: extraList[extraIndex].pre_stay_status === true ? 1 : 0,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        getlist();
        setLoader(false);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getpreStay();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getpreStay();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setLoader(false);
    // }
  };

  const getEmail = async (extraid, extraIndex) => {
    const res = await fetch(
      `${Config.apiurl}hotel/extra/confirm-email/change`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          extra_id: extraid,
          confirm_mail_status:
            extraList[extraIndex].confirm_mail_status === true ? 1 : 0,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLoader(false);
        getlist();
      } else if (data.reauth === true) {
        handleUnauthorized();
        getEmail();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getEmail();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setLoader(false);
    // }
  };

  const getDuplicate = async (extraid) => {
    const res = await fetch(`${Config.apiurl}hotel/extras/duplicate-copy`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        extra_id: extraid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getlist();

      } else if (data.reauth === true) {
        handleUnauthorized();
        getDuplicate();
      } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getDuplicate();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // console.log(res);
    // if (res.status !== 200 || !res) {
    //   console.log("error");
    //   setToastmodal(true);
    //   setToastmessage(data.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    // } else {
    //   console.log("success");
    //   setToastmodal(true);
    //   setToastmessage(data.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getlist();
    // }
  };

  const StatusChange = async () => {
    const res = await fetch(`${Config.apiurl}hotel/extra/change-status`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        extra_id: extraid,
        status: status,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setmodal_animationFlip(false);
        getlist();
      } else if (data.reauth === true) {
        handleUnauthorized();
        StatusChange();
      } else {
        console.log("error");
        setmodal_animationFlip(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      StatusChange();
    } else {
      console.log("error");
    }
   
  };

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  useEffect(() => {
    getlist();
  }, [id]);

  console.log(extraList);
  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">

          <div className="row project-wrapper">
            <div className="offer-list-main">
              {/* <div className="offer-list-top">
                <div className="extra-create-btn">
                  <h4 className="" style={{ marginLeft: "10px" }}>
                    {hotelname}
                  </h4>
                  <Link
                    type="button"
                    className="btn db-save-button"
                    to="/add-on-create"
                  >
                    Create New Add-on
                  </Link>
                </div>
              </div> */}
              <div className="offer-list-top">
                <div className="offer-heading">
                  {/* <h4 className="">{hotelname}</h4> */}
                  <h4 className="offer-list-head" style={{ color: "#008080" }}>
                    Add-ons
                  </h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                      <Link
                        type="button"
                        to={`/add-on-create/${id}`}
                        className="btn db-save-button "
                      >
                        Create New Add-on
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {loader === true ? (
                  <Loading />
                ) : (
                  <>
                    {extraList.length > 0 ? (
                      extraList.map((extra, i) => {
                        return (
                          <div className="col-md-12" key={i}>
                            <div className="card extra-lists">
                              <div className="extra-list-item">
                                <div
                                  className="extra-image_new"
                                  style={{ marginTop: "5px" }}
                                >
                                  {extra && extra.image ? (
                                    <img
                                      className="extra-image_new_list"
                                      src={`${Commonimagepath.apiurl}${extra.image}`}
                                      alt=""
                                      style={{ marginTop: "5px" }}
                                    />
                                  ) : (
                                    <img src="images/icon/extension.png" />
                                  )}
                                </div>
                                <div className="col-md-3 extra-title col-extra-wid">
                                  <Link
                                    to={`/add-on/view/${extra.extra_id}/${id}`}
                                  >
                                    <h6 className="extra_head">
                                      {extra.title}
                                    </h6>
                                    <p className="offer-code">
                                      ({extra.extra_key})
                                    </p>
                                  </Link>
                                </div>
                                {extra.sub_addons.length > 0 ? (
                                  <div className="sub_addon_list_head">
                                    Sub Add-on
                                  </div>
                                ) : (
                                  ""
                                )}
                               
                                {extra.sub_addons.map((data, ind) => (
                                  <div
                                    key={ind}
                                    className="extra-title_subaddon"
                                  >
                                    <img
                                      src="images/icon/tick.png"
                                      style={{ height: "20px" }}
                                    />
                                    <span style={{ marginLeft: "4px" }}>
                                      {data.title}
                                    </span>
                                  </div>
                                ))}
                                 <div className="extra-status-button">
                                 {/* {extra.status === true ? 

                                      <Link
                                        to="#"
                                        className="offer-status-enabled"
                                        onClick={()=>tog_animationFlip(extra.extra_id, 0)}
                                        style={{background:"#a4e785",borderColor:"#d2d2d2"}}
                                      >
                                        Enabled
                                      </Link>
                                      :
                                      <Link
                                      to="#"
                                      className="offer-status-enabled"
                                      onClick={()=>tog_animationFlip(extra.extra_id, 1)}
                                      style={{background:"#fe8c8c",borderColor:"#d2d2d2"}}
                                    >
                                      Disabled
                                    </Link>
                                   } */}
                                     <div
                                    className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                    dir="ltr"
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                                      id="inlineswitch5"
                                      style={{ width: "50px", height: "25px" }}
                                      value={extra.status}
                                      checked={extra.status === true ? true : false}
                                      onChange={(e) =>
                                        tog_animationFlip(extra.extra_id, extra.status === true ? 0 : 1)
                                      }
                                    />
                                  </div>
                                      <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        style={{ marginLeft: "10px" }}

                                        value={extra.pre_stay_status}
                                        checked={
                                          extra.pre_stay_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtraToggle(
                                            e.target.checked,
                                            "pre_stay_status",
                                            i,
                                            extra.extra_id
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Pre-Stay
                                      </label>


                                      <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        style={{ marginLeft: "15px" }}
                                        value={extra.confirm_mail_status}
                                        checked={
                                          extra.confirm_mail_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtraToggleemail(
                                            e.target.checked,
                                            "confirm_mail_status",
                                            i,
                                            extra.extra_id
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Confirmation Email
                                      </label>
                                  
                                  
                                </div>
                                {/* <div className="extra-status-button">
                                  {extra.status === true ? (
                                    <>
                                      <Link
                                        to={`/add-on/view/${extra.extra_id}/${id}`}
                                        className="offer-status-enabled"
                                        
                                      >
                                        Enabled
                                      </Link>
                                      <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        style={{ marginLeft: "10px" }}

                                        value={extra.pre_stay_status}
                                        checked={
                                          extra.pre_stay_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtraToggle(
                                            e.target.checked,
                                            "pre_stay_status",
                                            i,
                                            extra.extra_id
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Pre-Stay
                                      </label>


                                      <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        style={{ marginLeft: "15px" }}
                                        value={extra.confirm_mail_status}
                                        checked={
                                          extra.confirm_mail_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtraToggleemail(
                                            e.target.checked,
                                            "confirm_mail_status",
                                            i,
                                            extra.extra_id
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Confirmation Email
                                      </label>
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        to={`/add-on/view/${extra.extra_id}/${id}`}
                                        className="offer-status-enabled"
                                      >
                                        Draft
                                      </Link>
                                      <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        style={{ marginLeft: "10px" }}

                                        value={extra.pre_stay_status}
                                        checked={
                                          extra.pre_stay_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtraToggle(
                                            e.target.checked,
                                            "pre_stay_status",
                                            i,
                                            extra.extra_id
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Pre-Stay
                                      </label>


                                      <input
                                        className="form-check-input offer_include_check"
                                        type="checkbox"
                                        id="formCheck1"
                                        style={{ marginLeft: "15px" }}
                                        value={extra.confirm_mail_status}
                                        checked={
                                          extra.confirm_mail_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtraToggleemail(
                                            e.target.checked,
                                            "confirm_mail_status",
                                            i,
                                            extra.extra_id
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "5px" }}>
                                        Confirmation Email
                                      </label>
                                    </>
                                  )}
                                </div> */}
                                <span className="offer-links">
                                  <Link
                                    className="offer-duplicate-icon"
                                    to={`/add-on/view/${extra.extra_id}/${id}`}
                                    title="Edit"
                                    style={{ top: "4px" }}
                                  >
                                    <i className="ri-pencil-fill fs-16"></i>
                                  </Link>
                                  <Link
                                    className="offer-duplicate-icon"
                                    to=""
                                    title="Duplicate"
                                    onClick={() => getDuplicate(extra.extra_id)}
                                  >
                                    <img src="images/icon/copy.png" />
                                  </Link>
                                  {/* <Link
                                    className="offer-delete-icon"
                                    to=""
                                    title="Delete"
                                  >
                                    <img src="images/trash.png" />
                                  </Link> */}
                                  <Link
                                    className="offer-duplicate-icon"
                                    to=""
                                    title="Copy link"
                                  >
                                    <img src="images/icon/hyperlink.png" />
                                  </Link>
                                </span>
                              </div>
                            </div>

                          </div>
                        );
                      })
                    ) : (
                      <Nodatafound />
                      // <div className="card-body pt-0">
                      //   <div
                      //     className="no-content text-center"
                      //     style={{ fontSize: "16px" }}
                      //   >
                      //     No Data Found
                      //   </div>
                      // </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          id="flipModal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              Do you want to {status===0? "disable": "enable" } this add-on?
            </div>
            {/* <button
              className="offer_disable_pop_cancel" onClick={()=>setmodal_animationFlip(false)}>
             Cancel
            </button> */}
             <button
                className="btn btn-link fw-medium text-decoration-none"
                style={{ color: "#FF7979" }}
                onClick={()=>setmodal_animationFlip(false)}
              >
                {" "}
                <i className="ri-close-line me-1 align-middle"></i>
                Close{" "}
              </button>
            <button
              className="offer_disable_pop_yes"
              onClick={StatusChange}
           >
             Yes
            </button>
          </ModalBody>
        </Modal>

        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Extras;
