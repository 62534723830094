import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import Previousmodal from "./Previousmodal";
import { Link } from "react-router-dom";
import Commonimagepath from "../Commonimagepath";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function Loyalty({
  setTab,
  frequency,
  benifit,
  checke,
  setChecked,
  inputFieldsloyalty,
  removeInputFieldsloyalty,
  handleChangeloyalty,
  handleChangeeslo,
  handleChangetoggexp,
  display,
  setDisplay,
  loyalty,
  setLoyalty,
  handleloyalty,
  handleflatdate,
  loyaltynext,
  handleChangeloyaltyfile,
  hoteldetails,
  handleloyaltybasiclogo,
  formerror9,
  formerror10,
  loyaltyold,
  loyalprev,
  setLoyalprev,
  Prvfunction,
  closetab,
  loyalupdatestatus,
  setLoyalupdatestatus,
  loyal_info_status,
  loyalprevtab,
  submitconfirm,
  handleChangetoggloyalcheck,
  handleChangetoggloyaldisplay,
  loyaltyimages,
  setLoyaltyimages,
  loyalbutton,
  submitbutton,
  basiclevel,
  handleChangeloyaltylevel,
  addInputFieldloyal,
  loaderspin,
  handleChangetogg,
  defaultimage,
  setHoteldetails,
  popupid,
  setPopupId,
  popupcreate,
  setPopupcreate,
  logoFile,
  setLogoFile,
  tabimgcreate,
  setTabimagecreate,
  popuploop,
  setPopuploop,
  tabimg,
  setTabimage,
  handleImageClickcreate,
  onFileChangecreate,
  handleImageClickloop,
  onFileChangeloyalty,
}) {
  if (Array.isArray(loyalty)) {
    setLoyalty({
      loyalty_levels: [
        {
          name: "",
          condition: "or",
          expiry_status: false,
          benefits: [],
        },
        {
          name: "",
          condition: "or",
          expiry_status: false,
          benefits: [],
        },
        {
          name: "",
          condition: "or",
          expiry_status: false,
          benefits: [],
        },
      ],

      loyalty_program: {
        rate_display_status: true,
      },
    });
  }

  // if(hoteldetails.loyalty_program_status===true || checke==='yes'){
  //   setChecked('yes')
  // }
  // else if (hoteldetails.loyalty_program_status===false || checke==='no'){
  //   setChecked('no')

  // }
  // if ((Array.isArray(loyalty) && display==='no') || loyalty.loyalty_program.rate_display_status===false || display==='no'){
  //   setDisplay('no')

  // }
  // else if(loyalty.loyalty_program.rate_display_status===true || display==='yes'){
  //   setDisplay('yes')
  // }

  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  console.log(loyalty);
  console.log(logoFile);

  return (
    <div id="Loyalty" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          <div className="row">
            <div className="col-md-12 be-form-tax-title">
              <label
                for="firstNameinput"
                className="form-label be-form-tax-title-status be_form_label"
              >
                Do you want a free loyalty program for your hotel{" "}
                <span className="form-validation-required">*</span>
                <div class="info-tag-new">i</div>
                <span className="tooltiptext-new">
                  we highly recommend for this as it will give very good
                  conversion rate
                </span>
              </label>
              <div className="switch-field">
                <input
                  type="radio"
                  id="radio-one"
                  name="loyalty_program_status"
                  value={true}
                  checked={hoteldetails.loyalty_program_status === true}
                  onChange={handleChangetoggloyalcheck}
                  // onChange={loyaltycommontogg}
                />
                <label for="radio-one">Yes</label>
                <input
                  name="loyalty_program_status"
                  type="radio"
                  id="radio-two"
                  value={false}
                  checked={hoteldetails.loyalty_program_status === false}
                  onChange={handleChangetoggloyalcheck}
                />
                <label for="radio-two">No</label>
              </div>
            </div>
            {hoteldetails.loyalty_program_status === true ? (
              <div className="col-md-12 loyalty_include_section">
                <div className="row">
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      What you want to call your loyalty program?{" "}
                      <span className="form-validation-required">*</span>
                      <div class="info-tag-new">i</div>
                      <span className="tooltiptext-new">
                        Eg: Hotel Name Rewards program
                      </span>
                    </label>
                    <div className="tax_include_status_btn">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        placeholder=""
                        id="emailidInput"
                        value={loyalty.loyalty_program.program_name}
                        onChange={handleloyalty}
                        name="program_name"
                      />

                      <label style={{ color: "red" }}>
                        {" "}
                        {formerror9.loyalty_program}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      What is the basic discount percentage you wanted to give
                      your loyalty program?
                      <span className="form-validation-required">*</span>
                    </label>
                    <div className="tax_include_status_btn tax_include_status_display">
                      <select
                        className="form-select"
                        value={loyalty.loyalty_program.discount_percentage}
                        onChange={handleloyalty}
                        name="discount_percentage"
                      >
                        <option value="">Select</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20 </option>
                        <option value="other">Other</option>
                        <option
                          value={
                            loyalty.loyalty_program.discount_percentage ===
                              "5" ||
                            loyalty.loyalty_program.discount_percentage ===
                              "10" ||
                            loyalty.loyalty_program.discount_percentage ===
                              "15" ||
                            loyalty.loyalty_program.discount_percentage ===
                              "20" ||
                            loyalty.loyalty_program.discount_percentage ===
                              "other"
                              ? ""
                              : loyalty.loyalty_program.discount_percentage
                          }
                        >
                          {loyalty.loyalty_program.discount_percentage ===
                            "5" ||
                          loyalty.loyalty_program.discount_percentage ===
                            "10" ||
                          loyalty.loyalty_program.discount_percentage ===
                            "15" ||
                          loyalty.loyalty_program.discount_percentage ===
                            "20" ||
                          loyalty.loyalty_program.discount_percentage ===
                            "other"
                            ? ""
                            : loyalty.loyalty_program.discount_percentage}
                        </option>
                      </select>

                      {loyalty.loyalty_program.discount_percentage ===
                      "other" ? (
                        <input
                          type="number"
                          onKeyDown={(e) =>
                            symbolsrate.includes(e.key) && e.preventDefault()
                          }
                          className="form-control ml-2"
                          placeholder="eg: 2"
                          id="emailidInput"
                          min={0}
                          value={loyalty.loyalty_program.loyalty_other_discount}
                          onChange={handleloyalty}
                          name="loyalty_other_discount"
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                      <div className="discount_percentage_symbol">%</div>
                    </div>
                    <label style={{ color: "red" }}>
                      {" "}
                      {formerror9.loyalty_discount}{" "}
                    </label>
                    <label style={{ color: "red" }}>
                      {" "}
                      {formerror9.other_discount_percentage}{" "}
                    </label>
                  </div>

                  <div className="padding-5"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Logo URL :
                    </label>
                    <div className="tax_include_status_btn loyalty_field-width">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        placeholder=""
                        id="emailidInput"
                        value={loyalty.loyalty_program.club_logo_url}
                        onChange={handleloyalty}
                        name="club_logo_url"
                      />
                    </div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Club Condition URL :
                    </label>
                    <div className="tax_include_status_btn loyalty_field-width">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        placeholder=""
                        id="emailidInput"
                        value={loyalty.loyalty_program.club_condition_url}
                        onChange={handleloyalty}
                        name="club_condition_url"
                      />
                    </div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Privacy Policy URL :
                    </label>
                    <div className="tax_include_status_btn loyalty_field-width">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        placeholder=""
                        id="emailidInput"
                        value={loyalty.loyalty_program.club_policy_url}
                        onChange={handleloyalty}
                        name="club_policy_url"
                      />
                    </div>
                  </div>
                  <div className="padding-20"></div>
                  <div className="col-md-12">
                    <p className="beform-loyalty-level-title">
                      Fill in the Loyalty Details Below
                    </p>
                    <div className="be_loyalty_level">
                      <h5 className="be_loyalty_level_title">
                        Loyalty Club - Basic Level
                      </h5>
                      <span className="padding-5"></span>
                      <span className="be_form_loyalty_label_50">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Level Name{" "}
                          <span className="form-validation-required">*</span>
                          <div class="info-tag-new">i</div>
                          <span className="tooltiptext-new">
                            Name of the loyalty level you want to call, for
                            example Bronze
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          min="1"
                          max="5"
                          value={loyalty.loyalty_program.basic_level_name}
                          onChange={handleloyalty}
                          name="basic_level_name"
                        />
                      </span>
                      <span className="be_form_loyalty_label_50">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Logo <div class="info-tag-new mar_1">i</div>
                          <span className="tooltiptext-new">
                            Upload logo in this particular level, only accept
                            jpg,jpeg,png format
                          </span>
                          <span className="span-img-size">
                            ( 140 px * 40px)
                          </span>
                        </label>
                        <div className="form-translation-style_offer">
                          <ul
                            className="price_show_select_loyal"
                            id="price_show_type"
                          >
                            <li
                              className={
                                tabimgcreate === "upload" ? "active" : ""
                              }
                              onClick={(e) => setTabimagecreate("upload")}
                            >
                              <button className="photo_offer_pen_choose">
                                Upload Image
                              </button>
                              <input
                                className="photo_offer_input"
                                type="file"
                                name="image"
                                onChange={onFileChangecreate}
                                accept=".jpg, .jpeg, .webp, .png"
                              />
                            </li>

                            <li
                              className={
                                tabimgcreate === "list" ? "active" : ""
                              }
                              onClick={(e) => {
                                setPopupcreate(!popupcreate);
                                setTabimagecreate("list");
                              }}
                            >
                              <button
                                className="photo_offer_pen_choose"
                                type="button"
                              >
                                Choose From List
                              </button>
                            </li>
                          </ul>
                        </div>
                        {/* <input
                        type="file"
                        className="form-control"
                        placeholder=""
                        onChange={handleloyaltybasiclogo}
                        name="basic_level_logo"
                      /> */}
                        <label className="label_image_crop">
                          {" "}
                          (<span class="form-validation-required"></span>if you
                          do not resize it as per the mentioned size, it can
                          affect the quality of the images)
                        </label>

                        {/* {loyalty && loyalty.loyalty_program.basic_level_logo?
              <div className="be_form_room_info_label_full row">
                    <div className="col-sm-4 col-6 col-md-3 col-lg-2" >
                    <img src={`${Commonimagepath.apiurl}${loyalty.loyalty_program.basic_level_logo}`} alt="" className="position-relative" style={{  width: "100px",
                        
                         marginTop: "5px",
                         marginLeft: "5px",display:"block"}}/>
                    </div>
            </div>
            :""}
                    </span> */}
                        {logoFile ? (
                          <div className="" style={{ float: "left" }}>
                            {logoFile ? (
                              logoFile instanceof File ? (
                                <p className="pdf_img_style_img_loyal">
                                  {logoFile.name}
                                </p>
                              ) : (
                                <img
                                  src={`${Commonimagepath.apiurl}${logoFile}`}
                                  alt=""
                                  className="position-absolute"
                                  style={{
                                    width: "24px",
                                    marginTop: "21px",
                                    marginLeft: "10px",
                                    marginBottom: "10px",
                                    height: "24px",
                                  }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>

                      <div
                        className={
                          logoFile
                            ? !(logoFile instanceof File)
                              ? "padding_45 col-lg-5"
                              : "col-lg-5"
                            : "col-lg-5"
                        }
                        style={{ float: "left" }}
                      ></div>
                    </div>
                    {loyalty.loyalty_levels.map((data, index) => {
                      return (
                        <div className="be_loyalty_level" key={index}>
                          <span
                            className="remove_loyalty"
                            onClick={(e) => removeInputFieldsloyalty(index)}
                          >
                            X Remove
                          </span>
                          <span className="padding-5"></span>
                          <span className="be_form_loyalty_label_50 ">
                            <label
                              for="firstNameinput"
                              className="form-label be_form_label"
                            >
                              Name of Loyalty Level-{index + 1}{" "}
                              <span className="form-validation-required">
                                *
                              </span>
                              <div class="info-tag-new">i</div>
                              <span className="tooltiptext-new">
                                Name of the loyalty level you want to call, for
                                example Bronze
                              </span>
                            </label>
                            <select
                              className="form-select"
                              id="loyalty_level_change"
                              name="name"
                              onChange={(e) =>
                                handleChangeloyaltylevel(index, e)
                              }
                              value={data.name}
                            >
                              <option value="" name="loyalty">
                                Select
                              </option>
                              {basiclevel.map((level, indi) => {
                                return (
                                  <option
                                    key={indi}
                                    value={level.name}
                                    name="loyalty"
                                  >
                                    {level.name}
                                  </option>
                                );
                              })}
                              {/* <option value="bronze" name="loyalty">Bronze</option>
                         <option value="silver" name="loyalty">Silver</option>
                         <option value="gold" name="loyalty">Gold</option>*/}
                              <option value="other" name="loyalty">
                                Other
                              </option>
                            </select>
                            {formerror10.map((err, i) => {
                              return (
                                <>
                                  {index === i && err && err.loyalty ? (
                                    <label style={{ color: "red" }}>
                                      {err.loyalty}
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </span>
                          {data.name === "other" ? (
                            <span className="be_form_loyalty_label_30 other_loyalty_level">
                              <label
                                for="firstNameinput"
                                className="form-label form_spec_color be_form_label"
                              >
                                Name of Loyalty Level-1{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <div class="info-tag-new mar_1">i</div>
                                <span className="tooltiptext-new">
                                  Name of the loyalty level you want to call
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={data.other}
                                name="other"
                                onChange={(e) => handleChangeloyalty(index, e)}
                              />
                              {formerror10.map((err, i) => {
                                return (
                                  <>
                                    {index === i && err && err.loyaltyname ? (
                                      <label style={{ color: "red" }}>
                                        {err.loyaltyname}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="be_form_loyalty_label_50">
                            <label
                              for="firstNameinput"
                              className="form-label be_form_label"
                            >
                              Logo <div class="info-tag-new mar_1">i</div>
                              <span className="tooltiptext-new">
                                Upload logo in this particular level, only
                                accept jpg,jpeg,png format
                              </span>{" "}
                              <span className="span-img-size">
                                ( 140 px * 40px)
                              </span>
                            </label>
                            <div className="form-translation-style_offer">
                              <ul
                                className="price_show_select_loyal"
                                id="price_show_type"
                              >
                                <li
                                  className={
                                    tabimg === "upload" ? "active" : ""
                                  }
                                  onClick={(e) => setTabimage("upload")}
                                >
                                  <button className="photo_offer_pen_choose">
                                    Upload Image
                                  </button>
                                  <input
                                    className="photo_offer_input"
                                    type="file"
                                    name="image"
                                    onChange={(e) =>
                                      onFileChangeloyalty(index, e)
                                    }
                                    accept=".jpg, .jpeg, .webp, .png"
                                  />
                                </li>

                                <li
                                  className={tabimg === "list" ? "active" : ""}
                                  onClick={(e) => {
                                    setPopuploop(!popuploop);
                                    setTabimage("list");
                                    setPopupId(index);
                                  }}
                                >
                                  <button className="photo_offer_pen_choose">
                                    Choose From List
                                  </button>
                                </li>
                              </ul>
                            </div>

                            <label className="label_image_crop">
                              {" "}
                              (<span class="form-validation-required"></span>if
                              you do not resize it as per the mentioned size, it
                              can affect the quality of the images)
                            </label>
                            {loyalty && data.logo ? (
                              <div className="" style={{ float: "left" }}>
                                {data?.logo ? (
                                  data.logo instanceof File ? (
                                    <p className="pdf_img_style_img_loyal">
                                      {data.logo?.name}
                                    </p>
                                  ) : (
                                    <img
                                      src={`${Commonimagepath.apiurl}${data.logo}`}
                                      alt=""
                                      className="position-absolute"
                                      style={{
                                        width: "24px",
                                        marginTop: "40px",
                                        marginLeft: "10px",
                                        height: "24px",
                                      }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* {!Array.isArray(loyaltyold)?
                      loyaltyold.loyalty_levels.map((val,i)=>{
                        return(
                          <>
                           {index===i && val.logo?
                <div className="be_form_room_info_label_full row">
                    <div className="col-sm-4 col-6 col-md-3 col-lg-2" >
                    <img src={`${Commonimagepath.apiurl}${val.logo}`} alt="" className="position-relative" style={{  width: "100px",
                         
                         marginTop: "5px",
                         marginLeft: "5px",display:"block"}}/>
                    </div>
              </div>
              :""}
                          </>
                        )
                      }):""} */}
                          </span>
                          <div className="padding-5"></div>
                          <span className="be_form_loyalty_label_full">
                            <p className="loyalty_form_subhead">
                              Classification Rules
                            </p>
                          </span>
                          <span className="be_form_loyalty_label_40 loyalty_joint_style">
                            <span className="input-group ingroup">
                              <span
                                className="input-group-text input-joint-color1 loyal_font"
                                id="inputGroup-sizing-default"
                              >
                                Minimum Number of Reservations
                                <span className="form-validation-required">
                                  *
                                </span>
                              </span>
                              <input
                                type="number"
                                onKeyDown={(e) =>
                                  symbolsArr.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                min={0}
                                className="form-control siz-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={data.minimum_reservation}
                                name="minimum_reservation"
                                onChange={(e) => handleChangeloyalty(index, e)}
                              />
                              {formerror10.map((err, i) => {
                                return (
                                  <>
                                    {index === i &&
                                    err &&
                                    err.minimum_reservation ? (
                                      <label style={{ color: "red" }}>
                                        {err.minimum_reservation}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </span>
                          </span>
                          <span className="be_form_loyalty_label_20">
                            <select
                              className="form-select orand"
                              value={data.condition}
                              name="condition"
                              onChange={(e) => handleChangeloyalty(index, e)}
                            >
                              <option value="or">or</option>
                              <option value="and">and </option>
                            </select>
                          </span>
                          <span className="be_form_loyalty_label_40 loyalty_joint_style">
                            <span className="input-group ingroup">
                              <span
                                className="input-group-text input-joint-color1 loyal_font"
                                id="inputGroup-sizing-default"
                              >
                                Minimum Number of Room Nights
                                {/* <span className="form-validation-required">*</span> */}
                              </span>
                              <input
                                type="number"
                                onKeyDown={(e) =>
                                  symbolsArr.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                min={0}
                                className="form-control siz-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={data.minimum_room_nights}
                                name="minimum_room_nights"
                                onChange={(e) => handleChangeloyalty(index, e)}
                              />
                              {formerror10.map((err, i) => {
                                return (
                                  <>
                                    {index === i &&
                                    err &&
                                    err.minimum_room_nights ? (
                                      <label style={{ color: "red" }}>
                                        {err.minimum_room_nights}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </span>
                          </span>
                          <span className="padding-5"></span>

                          <span className="be_form_loyalty_label_25 be_loyalty_level_sec1">
                            <label
                              for="firstNameinput"
                              className="form-label be_loyalty_level_sec1_label be_form_label"
                            >
                              Expiry Status
                            </label>
                            <span
                              className="form-check form-switch form-switch-lg mb-3"
                              dir="ltr"
                            >
                              <input
                                type="checkbox"
                                name="expiry_status"
                                className="form-check-input loyalty_expiration"
                                value={data.expiry_status}
                                checked={
                                  data.expiry_status === true ? true : false
                                }
                                onChange={(e) => handleChangetogg(index, e)}
                              />
                            </span>
                          </span>
                          {data.expiry_status ? (
                            <span className="be_form_loyalty_label_30 loyalty_joint_style">
                              <span className="input-group period-sz">
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  {" "}
                                  Period<div class="info-tag-new">i</div>
                                  <span className="tooltiptext-new_loyal">
                                    Only reservations or room nights in the last
                                    X years will be considered
                                  </span>
                                </span>

                                <input
                                  type="number"
                                  onKeyDown={(e) =>
                                    symbolsrate.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                  min={0}
                                  className="form-control"
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-default"
                                  value={data.period}
                                  name="period"
                                  onChange={(e) =>
                                    handleChangeloyalty(index, e)
                                  }
                                />
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Year
                                </span>
                              </span>

                              {formerror10.map((err, i) => {
                                return (
                                  <>
                                    {index === i && err && err.period ? (
                                      <label style={{ color: "red" }}>
                                        {err.period}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </span>
                          ) : (
                            ""
                          )}

                          <span className="input-group be_form_loyalty_label_35a mb-3">
                            <span className="input-group">
                              <span
                                className="input-group-text input-joint-color1 loyal_font"
                                id="inputGroup-sizing-default"
                              >
                                Amount Spend
                              </span>
                              <input
                                type="number"
                                onKeyDown={(e) =>
                                  symbolsrate.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                min={0}
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={data.amount_spend}
                                name="amount_spend"
                                onChange={(e) => handleChangeloyalty(index, e)}
                              />
                            </span>
                            {formerror10.map((err, i) => {
                              return (
                                <>
                                  {index === i && err && err.amount_spend ? (
                                    <label style={{ color: "red" }}>
                                      {err.amount_spend}
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </span>

                          {data.expiry_status ? (
                            <span className="padding-5"></span>
                          ) : (
                            ""
                          )}
                          <span className="input-group be_form_loyalty_label_35a mb-3">
                            <span className="input-group">
                              <span
                                className="input-group-text input-joint-color1 loyal_font"
                                id="inputGroup-sizing-default"
                              >
                                Percentage of discount
                              </span>
                              <input
                                type="number"
                                onKeyDown={(e) =>
                                  symbolsrate.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                min={0}
                                className="form-control"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                value={data.discount_percentage}
                                name="discount_percentage"
                                onChange={(e) => handleChangeloyalty(index, e)}
                              />
                            </span>
                          </span>
                          {/* <span className="padding-5"></span> */}

                          <span
                            className={
                              data.expiry_status
                                ? "be_form_loyalty_label_full_new"
                                : "be_form_loyalty_label_full"
                            }
                          >
                            <span className="input-group be_loyalty_level_sec_select">
                              <span
                                className="input-group-text input-joint-color1 be_loyalty_level_sec3_label lo_label"
                                id="inputGroup-sizing-default"
                              >
                                Benefits
                              </span>
                              <span className="be_loyalty_level_sec_select_field">
                                <MultiSelect
                                  options={benifit}
                                  value={data.benefits}
                                  onChange={(val) =>
                                    handleChangeeslo(index, val)
                                  }
                                  // onChange={(val) =>
                                  // {
                                  //   const filteredVal = val.filter(option => option.value !== 'other');
                                  //   handleChangeeslo(index, filteredVal);

                                  //   }}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={false}
                                  aria-expanded="true"
                                />
                              </span>
                            </span>
                          </span>

                          {data.benefits ? (
                            <>
                              <span className="padding-5"></span>
                              {data.benefits.map((dara, i) => {
                                return (
                                  <div key={i}>
                                    {dara.value === "other" ? (
                                      <span className="be_form_loyalty_label_full other_benefits loyalty_joint_style">
                                        <span className="input-group">
                                          <span
                                            className="input-group-text input-joint-color1"
                                            id="inputGroup-sizing-default"
                                          >
                                            Other Benefits
                                          </span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-default"
                                            value={data.mul_other}
                                            name="mul_other"
                                            onChange={(e) =>
                                              handleChangeloyalty(index, e)
                                            }
                                          />
                                          {formerror10.map((err, i) => {
                                            return (
                                              <>
                                                {index === i &&
                                                err &&
                                                err.mul_other ? (
                                                  <label
                                                    style={{ color: "red" }}
                                                  >
                                                    {err.mul_other}
                                                  </label>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            );
                                          })}
                                        </span>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="loyaltyappendmore"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Do you want to display your loyalty rates to meta search
                      platforms?
                      <span className="form-validation-required">*</span>
                      <div class="info-tag-new ">i</div>
                      <span className="tooltiptext-new">
                        We strongly advise you to respond positively to this
                        question because it will help you achieve higher
                        conversion rates across all online platforms.
                      </span>
                    </label>
                    <div className="switch-field">
                      <input
                        type="radio"
                        id="radio-three"
                        name="rate_display_status"
                        value={true}
                        checked={
                          loyalty.loyalty_program.rate_display_status === true
                        }
                        onChange={handleChangetoggloyaldisplay}
                      />
                      <label for="radio-three">Yes</label>
                      <input
                        type="radio"
                        id="radio-four"
                        name="rate_display_status"
                        value={false}
                        checked={
                          loyalty.loyalty_program.rate_display_status === false
                        }
                        onChange={handleChangetoggloyaldisplay}
                      />
                      <label for="radio-four">No</label>
                    </div>
                  </div>
                </div>
                <div className="be-form-add-anthor-section">
                  <Link
                    className="room-info-addmore be_form_common_add_more"
                    id="room-info-addmore"
                    data-count="1"
                    to="#"
                    onClick={addInputFieldloyal}
                  >
                    Add Another
                  </Link>
                  <div className="add-more-bottom-space"></div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="padding-20"></div>
            <div className="tab_nav_section">
              <div className="tab_nav_section_btns_form">
                {/* <button
                  className="btn btn-be-form-cancel btn-be-back"
                  onClick={(e) => setTab("Tax")}
                >
                  Prev
                </button>
                <button className="btn btn-success btn-be-common" onClick={loyaltynext}>Save</button> */}

                <button
                  className="btn btn-be-form-cancel btn-be-back"
                  onClick={loyalprevtab}
                >
                  Prev
                </button>
                {loyalupdatestatus === true && loyalbutton == true ? (
                  <button
                    className="btn btn-success btn-be-common"
                    onClick={loyaltynext}
                    disabled
                  >
                    Save
                  </button>
                ) : loyalupdatestatus === true && loyalbutton == false ? (
                  <button
                    className="btn btn-success btn-be-common"
                    onClick={loyaltynext}
                  >
                    Save
                  </button>
                ) : loyalupdatestatus === false &&
                  loyal_info_status === true ? (
                  ""
                ) : loyalbutton == true ? (
                  <button
                    className="btn btn-success btn-be-common"
                    onClick={loyaltynext}
                    disabled
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="btn btn-success btn-be-common"
                    onClick={loyaltynext}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            {hoteldetails.hotel_form_status === 3 ? (
              ""
            ) : hoteldetails.is_approved === false &&
              hoteldetails.hotel_info_status === true &&
              hoteldetails.room_info_status === true &&
              hoteldetails.hk_info_status === true &&
              hoteldetails.tax_info_status === true &&
              hoteldetails.loyalty_info_status === true ? (
              <div className="loyalty_submit_confirm">
                <h4>You filled all information,Do you want to submit?</h4>
                {submitbutton === true ? (
                  <button
                    className="btn btn_loyal_confirm"
                    onClick={submitconfirm}
                    disabled
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="btn btn_loyal_confirm"
                    onClick={submitconfirm}
                  >
                    Submit
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {loyalprev === true ? (
          <Previousmodal
            message="Loyalty"
            roomprev={loyalprev}
            setRoomprev={setLoyalprev}
            tag="loyalty"
            Prvfunction={Prvfunction}
            closetab={closetab}
          />
        ) : (
          ""
        )}
      </div>
      <Modal
        id="flipModal"
        isOpen={popupcreate}
        toggle={() => {
          setPopupcreate(!popupcreate);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopupcreate(!popupcreate)}></ModalHeader>
        <ModalBody className="modal-body text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          <div className="offer_image_list_ur">
            {defaultimage.map((imageSrc) => (
              <>
              <div className="d-flex justify-content-center">
              <div className="loyalty_level_box">
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        alt={`Image ${ind + 1}`}
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() => handleImageClickcreate(imgs, popupid)}
                      />
                    );
                  })}
                </div>
                </div>
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        id="flipModal"
        isOpen={popuploop}
        toggle={() => {
          setPopuploop(!popuploop);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopuploop(!popuploop)}></ModalHeader>
        <ModalBody className="modal-body text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          <div className="offer_image_list_ur">
            {defaultimage.map((imageSrc, index) => (
              <>
               <div className="d-flex justify-content-center">
               <div className="loyalty_level_box">
                <h5>{imageSrc.title}</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }}
                >
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        alt={`Image ${ind + 1}`}
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={(e) => handleImageClickloop(imgs, popupid)}
                      />
                    );
                  })}
                </div>
                </div>
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loyalty;
