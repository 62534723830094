import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import Select from "react-select";
import Commonimagepath from "../../../Commonimagepath";

function TempRoominformaton({ setTab, roominfo }) {
  return (
    <div id="RoomInfo" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          {roominfo.map((data, index) => {
            return (
              <div key={index}>
                <div className="addmore_roominfo">
                  <span className="room_info_count">
                    <span className="room_sect_no be_form_roomsec_1">
                      {index + 1}
                    </span>
                  </span>
                  <span className="be_form_room_info_label_30">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Room Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.room_detail.room_title}
                    />
                  </span>

                 
                  <span className="be_form_room_info_label_30">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Facilities
                    </label>

                    <MultiSelect
                      options={data.room_facilities}
                      value={data.room_facilities}
                      labelledBy="Select"
                      selectionType="counter"
                      closeOnChangedValue={true}
                      aria-expanded="true"
                    />
                  </span>
                
                  <div className="row">
                      <span className="be_room_info_label_30_img_new">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Room Images{" "}
                    </label>
                  </span>
                    {data.room_images.map((image, i) => {
                      return (
                        <div className="col-md-2 mb-4" key={i}>
                          <img
                            src={`${Commonimagepath.apiurl}${image}`}
                            alt=""
                            style={{ width: "65px", display: "block"}}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {/* {data.facility.map((fac,i)=>{
                return(
                  <div key={i}>
                     {fac.value==="other" ? (
                <span className="be_form_room_info_label_full other_facility">
                  <label
                    for="firstNameinput"
                    className="form-label form_spec_color be_form_label"
                  >
                    Other facilities{" "}
                    <span className="info-tag">
                      i
                      <span className="tooltiptext">
                        Enter any additional facility you want to mention{" "}
                      </span>
                    </span>
                  </label>
                  <input type="text" className="form-control" value={data.otherfacilitry}
                 />


                </span>
              ) : (
                ""
              )}
                  </div>
                )
              })} */}

                  <span className="be-form-line-separation"></span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Room Inventory
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={data.room_detail.room_inventory}
                      name="room_inventory"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Min. Number Of Adult{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={data.room_detail.min_no_adults}
                      name="minimum_adult"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Max. Number Of Adult{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={data.room_detail.max_no_adults}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      name="max_adult"
                    />
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Max. Number Of Child{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={data.room_detail.max_no_child}
                      name="max_child"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Max. Child Age
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={data.room_detail.max_child_age}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Baby Cot Status
                    </label>
                    <span
                      className="form-check form-switch form-switch-lg mb-3"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        name="baby_status"
                        className="form-check-input loyalty_expiration"
                        value={data.room_detail.baby_status}
                        checked={
                          data.room_detail.baby_status === true ? true : false
                        }
                      />
                    </span>
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Room Area
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={data.room_detail.room_area}
                      name="room_area"
                    />
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Extra Bed Status
                    </label>
                    <span
                      className="form-check form-switch form-switch-lg mb-3"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        name="extra_bed_status"
                        className="form-check-input loyalty_expiration"
                        value={data.room_detail.extra_bed_status}
                        checked={
                          data.room_detail.extra_bed_status === true
                            ? true
                            : false
                        }
                      />
                    </span>
                  </span>
                  <span className="be_form_room_info_label_25">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Number of bedrooms
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      id="room_nos"
                      maxlength="5"
                      value={data.room_detail.no_of_rooms}
                      addInputFieldRoom
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </span>
                  {data.room_beds.length > 0
                    ? data.room_beds.map((ele, ind) => {
                        return (
                          <>
                            <span class="roomtype-info">
                              <span class="be-form-line-separation">
                                <span class="room-sec-title">
                                  Room {ind + 1}
                                </span>
                              </span>
                              <span class="be_form_room_info_label_25">
                                <label
                                  for="firstNameinput"
                                  class="form-label be_form_label"
                                >
                                  Number of beds per Room
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  value={ele.bed_count}
                                  name="no_of_beds_per_room"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </span>
                              <span class="be_form_room_info_label_25">
                                <label
                                  for="firstNameinput"
                                  class="form-label be_form_label"
                                >
                                  BedType
                                  <span class="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={ele.bedtype.label}
                                  name="no_of_beds_per_room"
                                />
                              </span>
                              {/* { ele.bed_type==="other"?
               <span class="be_form_room_info_label_25 other_bedtype">
                  <label for="firstNameinput" class="form-label be_form_label">Other Bed Type 
                    <span class="form-validation-required">*</span>
                  </label>
                  <input type="number" class="form-control" placeholder="" value={ele.other}
                 onChange={(e)=>handleChangeesinner(index,ind,e.target.value,"other")}
                 name="other"/> */}

                              {/* </span>
                :""} */}
                              <span class="be_form_room_info_label_25">
                                <label
                                  for="firstNameinput"
                                  class="form-label be_form_label"
                                >
                                  Bed Size
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={ele.bedsize}
                                  name="bed_size"
                                />
                              </span>
                            </span>
                            <span class="be-form-line-separation"></span>
                          </>
                        );
                      })
                    : ""}

                  <span className="roomtype-append"></span>
                  <span className="be_form_room_info_label_full">
                    <label
                      for="firstNameinput"
                      className="form-label be_form_label"
                    >
                      Room Description{" "}
                    </label>
                    <textarea
                      className="db-desc-box"
                      rows="3"
                      value={data.room_detail.description}
                    ></textarea>
                  </span>
                  <span style={{ padding: "10px" }}></span>
                </div>
                <div className="clearfix"></div>
                <div className="room_info_appendmore"></div>
              </div>
            );
          })}

          <div className="tab_nav_section">
            <div className="tab_nav_section_btns_form">
              <button
                type="button"
                className="btn btn-be-form-cancel btn-be-back"
                onClick={(e) => setTab("Hotel-info")}
              >
                Prev
              </button>
              <button
                type="button"
                className="btn btn-success btn-be-common"
                onClick={(e) => setTab("Freequency")}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TempRoominformaton;
